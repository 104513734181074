<script>
import Layout from '@/views/layouts/main2'
import simplebar from 'simplebar-vue'
import service from '@/server/http_service'
import i18n from '@/i18n'
import DatePicker from 'vue2-datepicker'
import global_func from '@/global_function'
import moment from 'moment'
import Swal from 'sweetalert2'
// import {gmapApi} from 'vue2-google-maps'
// openlayer import
import 'ol/ol.css'
import * as ol from 'ol'
import * as layer from 'ol/layer'
import * as source from 'ol/source'
import * as proj from 'ol/proj'
import * as geom from 'ol/geom'
import * as style from 'ol/style'
import Overlay from 'ol/Overlay'
import CircleStyle from 'ol/style/Circle'
// import * as base from 'ol/layer/Base'
import LayerSwitcher from 'ol-layerswitcher'
import json from '@/assets/upload/file/route_data_1.JSON'
// import VuetifyMoney from 'vuetify-money'
import VuetifyMoney from '@/components/VuetifyMoney.vue'
// eslint-disable-next-line no-unused-vars
import rhm from '@/assets/js/routing_here_map.js'
export default {
  page: {
    title: i18n.t('transportation_plan_create.title'),
    meta: [{ name: 'description' }],
  },
  components: { Layout, simplebar, DatePicker, VuetifyMoney },
  data() {
    let account_lang = localStorage.getItem('account_lang')
    let user = localStorage.getItem('users')
    user = JSON.parse(user)
    var lang = i18n.t('transportation_plan_create')
    const src = require('../../../assets/images/ol/location_no_1.png')
    const src2 = require('../../../assets/images/ol/location_no_2.png')
    // const src = '/dtc_backhaul/src/assets/images/ol/location_1.svg'
    // const src2 = '/dtc_backhaul/src/assets/images/ol/location_2.svg'
    return {
      //genaral
      account_lang: account_lang,
      account_data: user,
      goods_lang: true,
      lang: lang,
      open_goods_type_status: false,
      open_location_sel_status: false,
      modals_map: false, // for modals เมื่อเปิดหน้าต่างเลือกประเภทสินค้า
      save_succes: lang.general.save_succes,
      debounce: null,
      debounce2: null,
      base_layer: null,
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false /* doesn't work with directive */,
      },
      money2: {
        decimal: '.',
        thousands: ',',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false /* doesn't work with directive */,
      },
      // setting prefix
      value5: '',
      label5: 'Money pt-BR',
      readonly5: false,
      disabled5: false,
      outlined5: false,
      clearable5: false,
      options5: {
        locale: 'en-US',
        prefix: '',
        suffix: '',
        length: 9,
        precision: 2,
      },
      add_tons_placeholder: lang.no1.placeholder.goods_tons,
      // map
      heightMap: '800px', // กำหนดความสูงด้วย methods getHeightDivMap()
      map: null,
      center: null,
      pick_up_1: null, // dtc สำนักงานใหญ่
      drop_1: null, // mega bangna
      zoom: 15,
      rotation: 0,
      // src: '<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44.01 50">' +
      //      '<circle fill="#fff" class="cls-1" cx="22.01" cy="22" r="13" />' +
      //      '<path fill="#343c48" class="cls-2" d="M37.56,8a21.92,21.92,0,0,0-31-.12h0L6.44,8A22.15,22.15,0,0,0,17.32,45.24L22,51.52l4.68-6.28A22.16,22.16,0,0,0,37.56,8ZM22,7.29A16.25,16.25,0,1,1,5.84,23.63h0v-.1A16.23,16.23,0,0,1,22,7.29Z" transform="translate(0.01 -1.52)" />' +
      //      '<text fill="#343c48" class="cls-3" transform="translate(17.86 27.4) scale(1.34 1)">1</text>' +
      //      '</svg>',
      src: src,
      src2: src2,
      coordinates: [11207429.40090933, 13541935.007697027],
      features: [
        {
          type: 'Feature',
          id: 'one',
          properties: {},
          geometry: {
            type: 'Point',
            coordinates: [11207429.40090933, 13541935.007697027],
          },
        },
      ],
      pinPointLayer: [], // layer ของ icon pin ตอนค้นหาจากการพิมพ์ชื่อสถานที่
      pinPointLayerOnMap: [], // layer ของ icon pin เมื่อตอน click บน map
      pinPointLayerFinal: [], // layer ของ icon pin ที่ใช้จริง
      directionLayer: [], // layer ของเส้นทางจากจุดรับส่ง
      directions_data: [], // ข้อมูลเส้นทางที่ได้จาก api
      routeLocalData_1: json, // ข้อมูลจาก api google ดีทีซี สำนักงานใหญ่ ไป เมกาบางนา
      halfRouteData: [], // พิกัดเพื่อหา new center
      popupOnMapActiveStatus: false,
      popupTravelOnMapActiveStatus: false,
      pinLocationTitle: '',
      pinLocationText: '',
      pinLocationTitle2: '',
      pinLocationText2: '',
      pinLocationLonLat: [],
      pinLocationEditFlag: false,
      search_result_flag: false,
      travelTime_data: [],
      travelTime_display: '',
      activeCurrentLocation_Status: false,
      activeSetZoomAndCenterCurrentLocation: false,
      pinPointCurrentLayerOnMap: [],
      current_location_permission_flag: false,
      // ตัวแปร ภาษาจาก i18n
      lang_no1: lang.no1,
      lang_no2: lang.no2,
      lang_no3: lang.no3,
      lang_no4: lang.no4,
      lang_no5: lang.no5,
      lang_sum: lang.summary_info,
      lang_g: lang.general,
      // goods type tab No.1
      dataGoodsType_master: [],
      dataGoodsType_data: [],
      dataGoodsType_data2: [],
      dataGoodsType_ck: [],
      dataGoodsType_note: '',
      dataGoodsType_etc: '',
      dataGoodsTerms_ck: '',
      dataGoodsTerms_ck_is_Invalid: false,
      data_goods_type_sel: null,
      data_goods_type_sel_full: null,
      data_goods_tons: '',
      data_goods_tons2: '',
      data_goods_kg: '',
      data_goods_weight_text: lang.no1.tons,
      data_goods_weight_select_flg: true,
      // No.2
      data_location: [
        {
          id: 1,
          icon: 'ri-map-pin-fill',
          texts: '',
          texts_en: '',
          LAT: '',
          LON: '',
          location_name: '',
          location_addr: '',
          cood: null,
          iconLocation: null,
        },
        {
          id: 2,
          icon: 'ri-map-pin-fill',
          texts: '',
          texts_en: '',
          LAT: '',
          LON: '',
          location_name: '',
          location_addr: '',
          cood: null,
          iconLocation: null,
        },
      ],
      two_way_status: '',
      data_location_sel: null,
      activeLocation_index: '',
      activeLocation_data: null,
      search_result: false,
      search_result_after: false,
      no_result: true,
      search_result_data: [
        {
          // ตัวอย่างข้อมูล
          texts: 'บริษัท ดีทีซี เอ็นเตอร์ไพรส์ จำกัด (สำนักงานใหญ่) แขวงบางนาเหนือ เขตบางนา กรุงเทพมหานคร 10260',
          texts_en: 'DTC Enterprise Co.,Ltd. (Head Office) Bang Na Nuea, Bang Na, Bangkok 10260',
          LAT: '13.6768000000',
          LON: '100.603694000',
        },
        {
          // ตัวอย่างข้อมูล 100.67805126527342 || 13.646063403410484
          texts: 'เมกาบางนา ต.บางแก้ว อำเภอบางพลี สมุทรปราการ 10540',
          texts_en: '',
          LAT: '13.646063403410484',
          LON: '100.67805126527342',
        },
        {
          LAT: '18.7917730000',
          LON: '98.9940160000',
          location_addr: ' ต.ช้างม่อย อ.เมืองเชียงใหม่ จ.เชียงใหม่ 50000',
          location_name: 'เชียงใหม่เกสท์เฮ้าส์',
          texts: 'เชียงใหม่เกสท์เฮ้าส์ ต.ช้างม่อย อ.เมืองเชียงใหม่ จ.เชียงใหม่ 50000',
          texts_en: 'Chiangmai Guesthouse Chang Moi, Mueang Chiang Mai, Chiang Mai 50000',
        },
        {
          LAT: '6.56521300000',
          LON: '101.248562000',
          location_addr: ' ต.พร่อน อ.เมืองยะลา จ.ยะลา 95160',
          location_name: 'ยะลา',
          texts: 'ยะลา ต.พร่อน อ.เมืองยะลา จ.ยะลา 95160',
          texts_en: 'Yala Phron, Mueang Yala, Yala 95160',
        },
      ],
      current_favorite_location_data: [],
      location_previous_plans_data: [],
      search_text: '',
      distanceRaw: 0,
      distanceCal: 0,
      distanceHereMap: 0,
      totalCost: null,
      prices_start: 0,
      prices_pre_km: 0,
      // No.3
      date_time_delivery: [
        {
          id: 1,
          icon: 'ri-time-fill',
          date: '',
        },
      ],
      date_time_delivery_new: [],
      dateNow: moment().format('dd DD-MM-YYYY'),
      dataTimeRange: [
        {
          id: 1,
          select_txt: '00:00 AM - 02:00 AM',
          dataTimeStart: '00:00',
          dataTimeEnd: '02:00',
        },
        {
          id: 2,
          select_txt: '02:00 AM - 04:00 AM',
          dataTimeStart: '02:00',
          dataTimeEnd: '04:00',
        },
        {
          id: 3,
          select_txt: '04:00 AM - 06:00 AM',
          dataTimeStart: '04:00',
          dataTimeEnd: '06:00',
        },
        {
          id: 4,
          select_txt: '06:00 AM - 08:00 AM',
          dataTimeStart: '06:00',
          dataTimeEnd: '08:00',
        },
        {
          id: 5,
          select_txt: '08:00 AM - 10:00 AM',
          dataTimeStart: '08:00',
          dataTimeEnd: '10:00',
        },
        {
          id: 6,
          select_txt: '10:00 AM - 12:00 PM',
          dataTimeStart: '10:00',
          dataTimeEnd: '12:00',
        },
        {
          id: 7,
          select_txt: '12:00 PM - 14:00 PM',
          dataTimeStart: '12:00',
          dataTimeEnd: '14:00',
        },
        {
          id: 8,
          select_txt: '14:00 PM - 16:00 PM',
          dataTimeStart: '14:00',
          dataTimeEnd: '16:00',
        },
        {
          id: 9,
          select_txt: '16:00 PM - 18:00 PM',
          dataTimeStart: '16:00',
          dataTimeEnd: '18:00',
        },
        {
          id: 10,
          select_txt: '18:00 PM - 20:00 PM',
          dataTimeStart: '18:00',
          dataTimeEnd: '20:00',
        },
        {
          id: 11,
          select_txt: '20:00 PM - 22:00 PM',
          dataTimeStart: '20:00',
          dataTimeEnd: '22:00',
        },
        {
          id: 12,
          select_txt: '22:00 PM - 00:00 AM',
          dataTimeStart: '22:00',
          dataTimeEnd: '00:00',
        },
      ],
      time_delivery: [],
      allDay_status: 'checked', // ค่าเริ่มต้น
      deadline_select: [
        {
          id: 1,
          texts_th: 'ภายในวันนี้',
          texts_en: 'ภายในวันนี้2',
          status: '',
        },
        {
          id: 2,
          texts_th: 'ก่อนเริ่มแผนงาน 1 วัน',
          texts_en: 'ก่อนเริ่มแผนงาน 1 วัน2',
          status: '',
        },
        {
          id: 3,
          texts_th: 'ก่อนเริ่มแผนงาน 3 วัน',
          texts_en: 'ก่อนเริ่มแผนงาน 3 วัน2',
          status: '',
        },
        {
          id: 4,
          texts_th: 'ก่อนเริ่มแผนงาน 7 วัน',
          texts_en: 'ก่อนเริ่มแผนงาน 7 วัน2',
          status: '',
        },
      ],
      deadline_selected: [],
      data_between_date: {},
      // No.4
      // truck
      dataTruckType: [],
      dataTruckType_ck: [],
      dataTruckType_note: [],
      dataTruckTypeGroup: [],
      dataTruckTypeGroup_ck: [],
      dataTruckTypeGroup_note: '',
      dataNewTruck: [],
      dataGroupTruck: [
        {
          id: '1',
          name_group: 'ทั้งหมด',
        },
      ],
      dataNewTruck_Invalid: true,
      dataNewTruck_add: [],
      open_truck_type_status: false,
      data_truck_type_sel: [],
      data_truck_type_sel_txt: null,
      data_truck_type_sel_id: null,
      data_truck_type_sel_flag: 0,
      data_truck_type_group_sel: null,
      data_truck_type_group_sel_id: null,
      data_truck_type_group_sel_flag: 0,
      data_truck_type_standard_cost: [],
      budget_display: false,
      all_cost_display: false,
      all_cost_btn: false,
      // service etc
      open_service_sel_status: false,
      open_service_sel: null,
      data_service_etc: [
        {
          service_etc_id: 1,
          name_th: 'พนักงานช่วยขนของ',
          name_en: 'พนักงานช่วยขนของ2',
          service_sel_status: '',
        },
        {
          service_etc_id: 2,
          name_th: 'พนักงานเพิ่มเติม',
          name_en: 'พนักงานเพิ่มเติม2',
          service_sel_status: '',
        },
        {
          service_etc_id: 3,
          name_th: 'บริการพาเลทรองพื้น',
          name_en: 'บริการพาเลทรองพื้น2',
          service_sel_status: '',
        },
        {
          service_etc_id: 4,
          name_th: 'อุปกรณ์ช่วยขนของ',
          name_en: 'อุปกรณ์ช่วยขนของ2',
          service_sel_status: '',
        },
        {
          service_etc_id: 5,
          name_th: 'อื่นๆ',
          name_en: 'อื่นๆ2',
          service_sel_status: '',
        },
      ],
      data_service_sel: '',
      data_service_sel_full: '',
      data_service_ck_note: '',
      data_offers_allowed: '',
      // หน้าสรุปการสร้างแผนงาน
      summary_info_plan_status: false,
      // แผนงานเฉพาะ
      sp_plan_data: {},
      sp_create_flag: 0,
      sp_send_offer_flag: '',
    }
  },
  computed: {
    // ol: window.ol,
    // google: window.google,
  },
  watch: {
    data_location: {
      handler: function () {
        var lo_1 = this.data_location[0]
        var lo_2 = this.data_location[1]
        let check1 = lo_1.LAT != null && lo_1.LAT != undefined && lo_1.LAT != ''
        let check2 = lo_2.LAT != null && lo_2.LAT != undefined && lo_2.LAT != ''
        if (check1 && check2) {
          // this.calDirections()
        }
      },
      deep: true,
    },
    date_time_delivery: {
      handler: function () {
        var date_select = moment(this.date_time_delivery[0].date).format('YYYY-MM-DD')
        var date_now = moment().format('YYYY-MM-DD')
        date_select += ' 00:00:00'
        date_now += ' 00:00:00'
        this.data_between_date = global_func.Between2Date(date_now, date_select)
        // console.log(data_between)
      },
      deep: true,
    },
  },
  async mounted() {
    // let googleApi = document.createElement('script')
    // googleApi.setAttribute('src', 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDp8Trf5wSVyYi-V8cxkPt4EE0aPy_fVBw')
    // document.head.appendChild(googleApi)
    // this.setDefaultMapSetting()
    await this.arrBaseLayer()
    await this.getDataGoodsType()
    await this.getTruckType()
    await this.getTruckTypeGroup()
    await this.getHeightDivMap()
    await this.initiateMap()
    await this.checkCurrentLocationPermission()
    // this.setGooleApi()
    let that = this
    setTimeout(function () {
      that.check_sp_plan_data()
    }, 100)
  },
  methods: {
    // map and direction
    arrBaseLayer() {
      // this.base_layer = [{"rr":"1"}]
      var arr = []
      var P_map = {}
      var dee_map_th2 = new layer.Tile({
        title: 'Dee-MapV2 (Thai)',
        type: 'base',
        visible: false,
        maxZoom: 22,
        source: new source.TileWMS({
          url: 'https://www.deemap.com/DeeMap/gwc/service/wms?',
          params: {
            'customer_key': '9P8qe4ndbB8ep6hJ',
            'LAYERS': 'DeeMap2_THA_Map',
            'FORMAT': 'image/png8',
            'VERSION': '1.1.1',
            'TILED': true,
          },
        }),
      })
      arr.unshift(dee_map_th2)
      // console.log(arr)
      P_map.dee_map_th2 = dee_map_th2

      var dee_map_en2 = new layer.Tile({
        title: 'Dee-MapV2 (En)',
        type: 'base',
        visible: false,
        maxZoom: 22,
        source: new source.TileWMS({
          url: 'https://www.deemap.com/DeeMap/gwc/service/wms?',
          params: {
            customer_key: '9P8qe4ndbB8ep6hJ',
            'LAYERS': 'DeeMap2_THA_Map_EN',
            'FORMAT': 'image/png8',
            'VERSION': '1.1.1',
            'TILED': true,
          },
        }),
      })
      arr.unshift(dee_map_en2)
      P_map.dee_map_en2 = dee_map_en2

      var layerNokiaHere = new layer.Tile({
        title: 'HereMap',
        type: 'base',
        visible: false,
        maxZoom: 18,
        source: new source.XYZ({
          url: 'http://1.base.maps.cit.api.here.com/maptile/2.1/maptile/newest/normal.day/{z}/{x}/{y}/256/png8?app_id=o9W16gheao25dXp9a9Kg&app_code=Smo6mT91hk9YhqJSclXrSA&lg=THA&lg2=ENG&xnlp=CL_JSMv2.5.3,SID_A78335BD-4DDE-4BBB-B544-B856888E9E89',
        }),
      })
      arr.unshift(layerNokiaHere)
      P_map.layerNokiaHere = layerNokiaHere

      var satellite = new layer.Tile({
        title: 'Satellite',
        type: 'base',
        visible: false,
        maxZoom: 20,
        source: new source.XYZ({
          url: 'http://1.aerial.maps.cit.api.here.com/maptile/2.1/maptile/newest/hybrid.day/{z}/{x}/{y}/256/png8?app_id=o9W16gheao25dXp9a9Kg&app_code=Smo6mT91hk9YhqJSclXrSA&lg=THA&lg2=ENG',
        }),
      })
      arr.unshift(satellite)
      P_map.satellite = satellite

      var openstreetmap = new layer.Tile({
        // A layer must have a title to appear in the layerswitcher
        title: 'OSM',
        // Again set this layer as a base layer
        type: 'base',
        visible: true,
        source: new source.OSM(),
      })
      arr.unshift(openstreetmap)
      P_map.openstreetmap = openstreetmap

      this.base_layer = { arr: arr, POINT: P_map }
    },
    initiateMap() {
      // console.log(ol)
      var map = new ol.Map({
        target: this.$refs.map_1,
        // layers: [
        //   new layer.Tile({
        //     source: new source.OSM(),
        //   }),
        // ],
        view: new ol.View({
          center: proj.fromLonLat([100.60379663561206, 13.676865941765966]),
          zoom: 10,
        }),
      })
      this.map = map
      // this.base_layer.POINT["openstreetmap"].setVisible(true);
      var arr_layer = this.base_layer.arr
      var group_base = new layer.Group({
        // A layer must have a title to appear in the layerswitcher
        'title': 'Base Layer',
        fold: 'open',
        layers: arr_layer,
      })
      this.map.addLayer(group_base)

      var layerSwitcher = new LayerSwitcher({
        reverse: true,
        groupSelectStyle: 'group',
      })
      map.addControl(layerSwitcher)
      this.setActionOnMap()
    },
    setActionOnMap() {
      var vue = this
      this.map.on('singleclick', function (event) {
        vue.map.updateSize()
        // var feature = this.forEachFeatureAtPixel(event.pixel,
        //   // eslint-disable-next-line no-unused-vars
        //   function(feature,layer) {
        //     return feature
        //   })
        var coordinates = event.coordinate

        if (coordinates.length > 0) {
          if (vue.open_location_sel_status) {
            vue.setPinPointLocation_onMap(coordinates)
          }
        }
      })
    },
    updateSizeMap() {
      this.map.updateSize()
    },
    search_location(text) {
      this.search_text = text
      this.data_location[this.activeLocation_index].location = text
      // console.log(this.search_text)
      if (this.search_text != '') {
        this.search_result = true
      } else {
        this.search_result = false
      }
      if (this.search_result) {
        this.no_result = false
        this.search_result_after = false
        // ------------------- ดึงข้อมูลจาก API DEEMAP -------------------
        let api = 'https://api.deemap.com/service/search?keyword=' + text + '&key=a94c85e1275c462c8004429040b72c88' // คนหาจากเทค
        // console.log(api)
        // eslint-disable-next-line no-unused-vars
        this.axios.get(api).then(response => {
          // console.log(response.data)
          this.search_result_data = response.data.data
          var data = this.search_result_data
          let data2 = this.current_favorite_location_data
          if (data.length > 0) {
            for (let index = 0; index < data.length; index++) {
              const rowData = data[index]
              var str = rowData.texts
              var data_splice = this.location_split(str)
              // console.log(data_splice)
              this.search_result_data[index].location_name = data_splice.location_name
              this.search_result_data[index].location_addr = data_splice.location_addr

              let flg = 0
              let favorite_flg_time = ''
              for (let index2 = 0; index2 < data2.length; index2++) {
                const element = data2[index2]
                let check_lat = element.lat == JSON.stringify(rowData.LAT)
                let check_lon = element.lon == JSON.stringify(rowData.LON)
                if (check_lat && check_lon) {
                  flg = 1
                  favorite_flg_time = moment(element.created).format('YYYY-MM-DD HH:mm:ss')
                }
              }

              if (flg == 1) {
                this.search_result_data[index].favorite_flg = '1'
              } else {
                this.search_result_data[index].favorite_flg = '0'
              }

              this.search_result_data[index].favorite_flg_time = favorite_flg_time
            }
            this.no_result = false
            this.search_result_after = true
          } else {
            this.no_result = true
            this.search_result_after = false
          }
        })
      } else {
        this.getFavoriteAndHistoryLocation()
      }
    },
    search_location_OnMap(coordinates) {
      // eslint-disable-next-line no-unused-vars
      var pinPointData = ''
      var lonlat = proj.transform(coordinates, 'EPSG:3857', 'EPSG:4326')
      var lon = lonlat[0]
      var lat = lonlat[1]
      var coor = [lon, lat]
      var url = 'https://api.deemap.com/api/identify/lat=' + lat + '/lon=' + lon + '/radius=20/key=490ff3d36579892c482b3a71733425ed' // ใช่ค้นหาจาก lat lon
      this.axios.get(url).then(response => {
        // console.log('pin point location data')
        // console.log(response.data)
        // pinPointData = response.data.geocode[0]
        // this.search_text = pinPointData.TAM_TNAME + ' ' + pinPointData.AMP_TNAME + ' ' + pinPointData.PROV_TNAME
        this.setPinPointLocation_onMap(coor, response.data)
        // this.search_location(this.search_text)
        // console.log(this.search_result_data)
      })
    },
    setPinPointLocation(coordinate) {
      // remove layer if have layer already
      if (this.pinPointLayer[this.activeLocation_index]) {
        this.map.removeLayer(this.pinPointLayer[this.activeLocation_index])
      }
      if (this.pinPointLayerOnMap) {
        this.map.removeLayer(this.pinPointLayerOnMap)
      }
      // if (this.directionLayer) {
      //   this.popupTravelOnMapActiveStatus = false
      //   this.map.removeLayer(this.directionLayer)
      // }

      //set a new pinpoint at clicked coordinates
      var new_point = new ol.Feature({
        geometry: new geom.Point(proj.fromLonLat([coordinate[0], coordinate[1]])),
      })

      // check location index for select icon pinpoint
      var pinpoint = this.src
      if (this.activeLocation_index != 0) {
        pinpoint = this.src2
      }

      //set style of point
      new_point.setStyle(
        new style.Style({
          image: new style.Icon({
            // color: 'red',
            crossOrigin: 'anonymous',
            scale: 1,
            anchor: [0.5, 1],
            anchorOrigin: 'top-left',
            // size: [44, 100],
            // offset: [0, 10],
            // offsetOrigin: 'top-left',
            // imgSize: [44, 50],
            src: pinpoint,
            // src: 'data:image/svg+xml;utf8,' + pinpoint,
          }),
        }),
      )

      //put point on layer
      this.pinPointLayer[this.activeLocation_index] = new layer.Vector({
        source: new source.Vector({
          features: [new_point],
        }),
      })

      // set name of layer
      this.pinPointLayer[this.activeLocation_index].set('name', 'point_' + this.activeLocation_index)
      // add point on map , set new center point and zoom value
      let new_center = proj.fromLonLat([coordinate[0], coordinate[1]])

      // view.animate({
      //   center: new_center,
      //   duration: 2000,
      // })
      this.map.addLayer(this.pinPointLayer[this.activeLocation_index])

      var zoom = this.map.getView().getZoom()
      if (zoom < 12) {
        zoom = 12
      }
      this.map.getView().animate({
        center: new_center,
        zoom: zoom,
        duration: 800,
      })
      // set route when have 2 point already
      var lo_1 = this.data_location[0]
      var lo_2 = this.data_location[1]
      let check1 = lo_1.LAT != null && lo_1.LAT != undefined && lo_1.LAT != ''
      let check2 = lo_2.LAT != null && lo_2.LAT != undefined && lo_2.LAT != ''
      if (check1 && check2) {
        // local routing
        // this.calDirections()

        // google api routing
        // this.getDirectionsService_Google(lo_1, lo_2)

        // here map routing
        this.getDirectionsService_HereMap(lo_1, lo_2)
      }
    },
    setPinPointLocation_onMap(coordinates) {
      var that = this
      var lonlat = proj.transform(coordinates, 'EPSG:3857', 'EPSG:4326')
      var lon = lonlat[0]
      var lat = lonlat[1]
      var coordinate = [lon, lat]
      this.search_result_flag = false

      this.pinLocationLonLat = coordinate
      // remove layer if have layer already
      if (this.pinPointLayerOnMap) {
        this.map.removeLayer(this.pinPointLayerOnMap)
      }
      // if (this.directionLayer) {
      //   this.map.removeLayer(this.directionLayer)
      // }

      this.popupOnMapActiveStatus = false // ปิด popup เลือกตำแหน่งนี้
      this.popupTravelOnMapActiveStatus = false // ปิด popup แสดงข้อมูลสถานะ

      //set a new pinpoint at clicked coordinates
      var new_point = new ol.Feature({
        geometry: new geom.Point(proj.fromLonLat([coordinate[0], coordinate[1]])),
      })

      // check location index for select icon pinpoint
      var pinpoint = this.src
      // var pinpoint = this.$refs.pinPointIcon1
      if (this.activeLocation_index != 0) {
        pinpoint = this.src2
      }
      // var mysvg = new Image()
      // mysvg.src = 'data:image/svg+xml,' + pinpoint
      // mysvg.src = pinpoint

      //set style of point
      new_point.setStyle(
        new style.Style({
          image: new style.Icon({
            // color: 'red',
            crossOrigin: 'anonymous',
            scale: 1,
            anchor: [0.5, 1],
            anchorOrigin: 'top-left',
            // offset: [0, 10],
            // offsetOrigin: 'top-left',
            // imgSize: [44, 110],
            src: pinpoint,
            // src: mysvg,
            // src: 'data:image/svg+xml;utf8,' + pinpoint,
          }),
        }),
      )

      //put point on layer
      this.pinPointLayerOnMap = new layer.Vector({
        source: new source.Vector({
          features: [new_point],
        }),
      })

      // add point on map , set zoom value
      let new_center = proj.fromLonLat([coordinate[0], coordinate[1]])
      var zoom = this.map.getView().getZoom()
      this.map.addLayer(this.pinPointLayerOnMap)
      this.map.getView().animate({
        zoom: zoom,
        duration: 800,
      })

      // search location from pin (lat, lon)
      var url = 'https://api.deemap.com/service/identify/lat=' + lat + '/lon=' + lon + '/radius=20/key=490ff3d36579892c482b3a71733425ed'
      this.axios.get(url).then(response => {
        // console.log('pin point location data (radius : 20)')
        var location_data = response.data
        // console.log(location_data)

        // location data on popup
        var pinLocationTitle = this.lang_g.no_data
        var pinLocationText = '-'
        if (location_data.geofencing.length > 0) {
          pinLocationTitle = location_data.geofencing[0].LM_TNAME
          that.search_result_flag = true
        } else {
          var url = 'https://api.deemap.com/service/identify/lat=' + lat + '/lon=' + lon + '/radius=40/key=490ff3d36579892c482b3a71733425ed'
          this.axios.get(url).then(response => {
            // console.log('pin point location data (radius : 40)')
            var location_data = response.data
            // console.log(location_data)
            if (location_data.geofencing.length > 0) {
              that.pinLocationTitle = location_data.geofencing[0].LM_TNAME
              that.search_result_flag = true
            } else {
              var url = 'https://api.deemap.com/service/identify/lat=' + lat + '/lon=' + lon + '/radius=60/key=490ff3d36579892c482b3a71733425ed'
              this.axios.get(url).then(response => {
                // console.log('pin point location data (radius : 60)')
                var location_data = response.data
                // console.log(location_data)
                if (location_data.geofencing.length > 0) {
                  that.pinLocationTitle = location_data.geofencing[0].LM_TNAME
                  that.search_result_flag = true
                } else {
                  // that.pinLocationTitle = this.lang_g.no_data
                  that.pinLocationTitle = location_data.road[0].RD_TNAME
                  that.search_result_flag = false
                }
              })
            }
          })
        }
        var road = ''
        if (this.search_result_flag) {
          road = location_data.road[0].RD_TNAME + ' '
        }
        if (location_data.geocode.length > 0) {
          var pinPointData = location_data.geocode[0]
          pinLocationText = road + pinPointData.TAM_TNAME + ' ' + pinPointData.AMP_TNAME + ' ' + pinPointData.PROV_TNAME
        } else {
          pinLocationText = road
        }
        this.pinLocationTitle = pinLocationTitle
        this.pinLocationText = pinLocationText

        setTimeout(function () {
          // Popup showing the position the user clicked
          var container = that.$refs.popup
          // var content = this.$refs.popup_content
          var closer = that.$refs.popup_closer
          var overlay = new Overlay({
            element: container,
            autoPan: true,
            autoPanAnimation: {
              duration: 800,
            },
          })
          that.map.addOverlay(overlay)
          closer.onclick = function () {
            overlay.setPosition(undefined)
            closer.blur()
            return false
          }
          // content.innerHTML = strHTML
          overlay.setPosition(new_center)
          that.popupOnMapActiveStatus = true
        }, 1500)

        // set route when have 2 point already
        var lo_1 = this.data_location[0]
        var lo_2 = this.data_location[1]
        let check1 = lo_1.LAT != null && lo_1.LAT != undefined && lo_1.LAT != ''
        let check2 = lo_2.LAT != null && lo_2.LAT != undefined && lo_2.LAT != ''
        if (check1 && check2) {
          // local routing
          // this.calDirections()
          // google api routing
          // this.getDirectionsService_Google(lo_1, lo_2)
          // here map routing
          // this.getDirectionsService_HereMap(lo_1, lo_2)
        }
      })
    },
    setPinPointCurrentLocation_onMap(coordinates) {
      var lonlat = proj.transform(coordinates, 'EPSG:3857', 'EPSG:4326')
      var lon = lonlat[0]
      var lat = lonlat[1]
      var coordinate = [lon, lat]

      // this.pinLocationLonLat = coordinate
      // remove layer if have layer already
      if (this.pinPointCurrentLayerOnMap) {
        this.map.removeLayer(this.pinPointCurrentLayerOnMap)
      }

      //set a new pinpoint at current location coordinates data
      var new_point = new ol.Feature({
        geometry: new geom.Point(proj.fromLonLat([coordinate[0], coordinate[1]])),
      })

      //set style of point
      new_point.setStyle(
        new style.Style({
          image: new CircleStyle({
            radius: 8,
            fill: new style.Fill({
              color: '#0098D1',
            }),
            stroke: new style.Stroke({
              color: 'white',
              width: 3,
            }),
          }),
        }),
      )

      //put point on layer
      this.pinPointCurrentLayerOnMap = new layer.Vector({
        source: new source.Vector({
          features: [new_point],
        }),
      })

      // add point on map , set zoom value
      // let new_center = proj.fromLonLat([coordinate[0], coordinate[1]])
      this.map.addLayer(this.pinPointCurrentLayerOnMap)
      if (this.activeSetZoomAndCenterCurrentLocation) {
        var zoom = this.map.getView().getZoom()
        this.map.getView().animate({
          center: proj.fromLonLat([coordinate[0], coordinate[1]]),
          zoom: zoom,
          duration: 800,
        })
        this.activeSetZoomAndCenterCurrentLocation = false
      }
    },
    selectThisLocation() {
      if (!this.search_result_flag) {
        this.data_location[this.activeLocation_index].location_name = this.pinLocationTitle + ' ' + this.pinLocationText
      } else {
        this.data_location[this.activeLocation_index].location_name = this.pinLocationTitle
      }
      this.data_location[this.activeLocation_index].location_addr = this.pinLocationText
      // let lon_lat = this.pinLocationLonLat[0].split(',')
      this.data_location[this.activeLocation_index].LON = this.pinLocationLonLat[0]
      this.data_location[this.activeLocation_index].LAT = this.pinLocationLonLat[1]

      this.popupOnMapActiveStatus = false
      this.popupTravelOnMapActiveStatus = false

      // remove layer if have layer already
      var that = this
      if (that.pinPointLayerOnMap) {
        that.map.removeLayer(this.pinPointLayerOnMap)
      }

      // if (this.findLayer('route')) {
      //   that.map.removeLayer(this.findLayer('route'))
      // }

      this.setPinPointLocation(this.pinLocationLonLat)
      var vue = this
      setTimeout(function () {
        vue.activeLocation()
      }, 300)

      // set route when have 2 point already
      var lo_1 = this.data_location[0]
      var lo_2 = this.data_location[1]
      let check1 = lo_1.LAT != null && lo_1.LAT != undefined && lo_1.LAT != ''
      let check2 = lo_2.LAT != null && lo_2.LAT != undefined && lo_2.LAT != ''
      if (check1 && check2) {
        // local routing
        // this.calDirections()

        // google api routing
        // this.getDirectionsService_Google(lo_1, lo_2)

        // here map routing
        this.getDirectionsService_HereMap(lo_1, lo_2)
      }
    },
    activeEditLocationPinPointOnMap() {
      if (this.pinLocationEditFlag) {
        this.pinLocationEditFlag = false
      } else {
        this.pinLocationTitle2 = this.pinLocationTitle
        this.pinLocationText2 = this.pinLocationText
        this.pinLocationEditFlag = true
      }
    },
    editLocationPinPointOnMap() {
      this.pinLocationTitle = this.pinLocationTitle2
      this.pinLocationText = this.pinLocationText2
      this.pinLocationEditFlag = false
    },
    getDirectionsService_Google(lo_1, lo_2) {
      // eslint-disable-next-line no-undef
      const directionsService = new google.maps.DirectionsService()
      // eslint-disable-next-line no-undef
      // console.log(directionsService)
      var origin = lo_1.LAT + ',' + lo_1.LON
      var destination = lo_2.LAT + ',' + lo_2.LON
      var vue = this
      directionsService.route(
        {
          origin: origin,
          destination: destination,
          travelMode: 'DRIVING',
        },
        // {
        //   origin: '13.676865941765966,100.60379663561206',
        //   destination: '13.646063403410484,100.67805126527342',
        //   travelMode: 'DRIVING',
        // },
        // eslint-disable-next-line no-unused-vars
        (response, status) => {
          // console.log(response)
          // console.log(status)
          vue.directions_data = response.routes[0]
          vue.calDirectionsGoogleAPI()
        },
      )
    },
    calDirectionsGoogleAPI() {
      // eslint-disable-next-line no-unused-vars
      // this.directions_data = this.routeLocalData_1 // for local route data json
      // console.log(this.directions_data)
      if (this.directionLayer) {
        this.popupTravelOnMapActiveStatus = false
        this.map.removeLayer(this.directionLayer)
      }
      var locations = []
      var latlonData = this.directions_data.overview_path
      // console.log(latlonData)
      for (let index = 0; index < latlonData.length; index++) {
        const lat = latlonData[index].lat()
        const lng = latlonData[index].lng()
        // const lat = latlonData[index].lat
        // const lng = latlonData[index].lng
        locations[index] = [lng, lat]
      }
      // console.log([locations])

      var route = new geom.LineString(locations).transform('EPSG:4326', 'EPSG:3857')

      var new_point = new ol.Feature({
        geometry: route,
      })

      //set style of route line
      new_point.setStyle(
        new style.Style({
          stroke: new style.Stroke({
            width: 6,
            color: [0, 152, 209, 0.8],
          }),
        }),
      )
      // set vector
      var vectorLayer = new layer.Vector({
        source: new source.Vector({
          features: [new_point],
        }),
      })
      // set name of vector for easy finding after
      vectorLayer.set('name', 'route')
      this.directionLayer = []
      this.directionLayer = vectorLayer
      this.map.addLayer(this.directionLayer)
      // console.log(this.map.getLayers())
      // console.log(this.findLayer('route'))
      this.moveLayerBefore(this.findLayer('point_0'), this.findLayer('route'), this.map.getLayers())
      this.moveLayerBefore(this.findLayer('point_1'), this.findLayer('route'), this.map.getLayers())
      this.getKmFromLatLot()
      // console.log(this.pinPointLayer[0].get('name'))
      // console.log(this.pinPointLayer[1].get('name'))
    },
    getDirectionsService_HereMap_old(lo_1, lo_2) {

      var that = this
      // var app_id = 'o9W16gheao25dXp9a9Kg' //'V78Ttyq1RSEy7KsExlfq'//'o9W16gheao25dXp9a9Kg'
      // var app_code = 'Smo6mT91hk9YhqJSclXrSA' //'nqGgcND3Yoof9VDmsYc7Hg'//'Smo6mT91hk9YhqJSclXrSA'

      // var app_id = 'rBwB1Fllq3IHyosgCFs5'
      // var app_code = '-a-M-daFoGNJuMa7DjF4RA'

      // eslint-disable-next-line no-undef
      var platform = new H.service.Platform({
        'apikey': 'Due7AzLBaEnUBQSBPunaYDOLfk4fnPCQe1YuN6ys',
      })

      var origin = lo_1.LAT + ',' + lo_1.LON
      // var origin2 = lo_1.LON + ',' + lo_1.LAT
      var destination = lo_2.LAT + ',' + lo_2.LON
      // var destination2 = lo_2.LON + ',' + lo_2.LAT

      // eslint-disable-next-line no-unused-vars
      // var routingParameters = {
      //   mode: 'fastest;truck',
      //   waypoint0: 'geo!' + origin, //'geo!50.1120423728813,8.68340740740811',
      //   waypoint1: 'geo!' + destination, //'geo!52.5309916298853,13.3846220493377',
      //   representation: 'display',
      //   routeattributes: 'labels',
      //   alternatives: 2,
      // }
      var routingParameters = {
        'transportMode': 'truck',
        'routingMode': 'fastest',
        'origin': origin, //'geo!50.1120423728813,8.68340740740811',
        'destination': destination, //'geo!52.5309916298853,13.3846220493377',
        'return': 'polyline',
      }
      var router = platform.getRoutingService(null, 8)
      router.calculateRoute(
        routingParameters,
        function (result) {
          // console.log(result)
          if (result.response.route) {
            // console.log('here map')
            // console.log(result.response)
            that.directions_data = result.response
            that.calDirectionsHereMap()
          }
        },
        function (error) {
          alert(error.message)
        },
      )
    },
    getDirectionsService_HereMap(lo_1, lo_2) {

      let that = this
      rhm.getDirectionsService(lo_1, lo_2, e => {
        // console.log(e)
        that.directions_data = e
        that.calDirectionsHereMap()
      })
    },
    calDirectionsHereMap() {

      // remove layer if have layer already
      if (this.findLayer('route')) {
        this.popupTravelOnMapActiveStatus = false
        this.removeLayerByName('route')
      }

      var locations = []
      var latlonData = this.directions_data.route[0].shape
      // console.log(latlonData)
      var timeAndDistance = this.directions_data.route[0].leg[0]

      for (let index = 0; index < latlonData.length; index++) {
        let lat_lon = latlonData[index].split(',')
        locations[index] = [parseFloat(lat_lon[1]), parseFloat(lat_lon[0])]
      }

      // for set new center
      var half = (latlonData.length / 2).toFixed(0)
      this.halfRouteData = locations[half]

      var route = new geom.LineString(locations).transform('EPSG:4326', 'EPSG:3857')
      // console.log(route)
      var new_point = new ol.Feature({
        geometry: route,
      })

      //set style of route line
      new_point.setStyle(
        new style.Style({
          stroke: new style.Stroke({
            width: 6,
            color: [0, 152, 209, 0.8],
          }),
        }),
      )
      // set vector
      var vectorLayer = new layer.Vector({
        source: new source.Vector({
          features: [new_point],
        }),
      })
      // set name of vector for easy finding after
      vectorLayer.set('name', 'route')
      this.directionLayer = []
      this.directionLayer = vectorLayer
      this.getTimeAndDistant_HereMap(timeAndDistance)
    },
    getTimeAndDistant_HereMap(timeAndDistance) {
      // var that = this
      var route = timeAndDistance
      var travelTime = route.travelTime
      var length = route.length

      // for (var i = 0; i < route.maneuver.length; i++) {
      //   travelTime += route.maneuver[i].travelTime
      //   length += route.maneuver[i].length
      // }
      this.distanceHereMap = length
      this.travelTime_data = global_func.toHHMMSS(travelTime.toString())
      this.travelTime_display = this.travelTime_data['hours'] + ' ' + this.lang_g.time.hours + ' ' + this.travelTime_data['minutes'] + ' ' + this.lang_g.time.minutes
      this.calNewZoomAndCenterOnMap()
      this.getKmFromLatLot()
    },
    calNewZoomAndCenterOnMap() {
      // console.log(this.halfRouteData)
      var distance = this.distanceHereMap
      distance = distance / 1000
      var new_center = []
      var zoom = 12
      if (distance > 800) {
        zoom = 6
      } else if (distance > 600) {
        zoom = 7
      } else if (distance > 400) {
        zoom = 8
      } else if (distance > 200) {
        zoom = 9
      } else if (distance > 50) {
        zoom = 11
      } else if (distance > 10) {
        zoom = 12
      } else {
        zoom = 13
      }
      let cood = proj.fromLonLat(this.halfRouteData)
      new_center = cood
      this.map.getView().animate({
        center: new_center,
        zoom: zoom,
        duration: 800,
      })
      var that = this
      setTimeout(function () {
        that.map.addLayer(that.directionLayer)
        that.moveLayerBefore(that.findLayer('point_0'), that.findLayer('route'), that.map.getLayers())
        that.moveLayerBefore(that.findLayer('point_1'), that.findLayer('route'), that.map.getLayers())
      }, 1000)
    },
    getKmFromLatLot(mode) {
      if (mode) {
        // คำนวนระยะทาง (เป็นเส้นตรง)
        let check1 = this.data_location[0].LAT != ''
        let check2 = this.data_location[0].LON != ''
        let check3 = this.data_location[1].LAT != ''
        let check4 = this.data_location[1].LON != ''
        let distance = 0
        // var cost = 0
        this.distanceRaw = 0
        if (check1 && check2 && check3 && check4) {
          const lat1 = this.data_location[0].LAT
          const lon1 = this.data_location[0].LON
          const lat2 = this.data_location[1].LAT
          const lon2 = this.data_location[1].LON
          distance = global_func.getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2)
          distance = this.directions_data.legs[0].distance.value // distance from google api data
          distance = distance / 1000
          this.distanceRaw = distance
          if (this.two_way_status == 'checked') {
            distance = distance * 2
            this.distanceCal = distance.toFixed(2)
          } else {
            this.distanceCal = distance.toFixed(2)
          }
        }
      } else {
        var value = this.distanceHereMap
        var distance = value / 1000
        this.distanceRaw = distance
        if (this.two_way_status == 'checked') {
          distance = distance * 2
          this.distanceCal = distance.toFixed(1)
        } else {
          this.distanceCal = distance.toFixed(1)
        }
      }
      this.activeTravelInfoPopup()
      // this.calTotalServiceCost()
      this.calStandardCost()
      // console.log(this.distanceCal)
    },
    activeTravelInfoPopup() {
      var that = this
      // Popup showing the position the user clicked
      var container = that.$refs.popup_travel_content
      var container_main = that.$refs.popup_travel
      // var content = this.$refs.popup_content
      var closer = that.$refs.popup_travel_closer
      var overlay = new Overlay({
        element: container_main,
        autoPan: true,
        autoPanAnimation: {
          duration: 800,
        },
      })
      that.map.addOverlay(overlay)
      closer.onclick = function () {
        overlay.setPosition(undefined)
        closer.blur()
        return false
      }
      overlay.on('change:position', function () {
        var width = container.offsetWidth
        var height = container.offsetHeight + 30
        var centerX = -width / 2
        var position = [centerX, -height]
        this.setOffset(position)
      })

      // content.innerHTML = strHTML
      let cood = proj.fromLonLat(this.halfRouteData)
      let new_center = cood
      overlay.setPosition(new_center)
      that.popupTravelOnMapActiveStatus = true
    },
    calTotalServiceCost() {
      // คำนวณราคาทั้งหมด
      this.distanceCal = 0
      this.prices_start = 0
      this.prices_pre_km = 0
      var dRaw = this.distanceRaw
      var cost = 0
      var countService = 0
      var flagCostStart = 0
      if (this.two_way_status == 'checked') {
        // เช็ค ไป - กลับ
        dRaw = dRaw * 2
      }
      for (let index = 0; index < this.dataTruckType.length; index++) {
        // เช็คประเภทรถที่เลือก
        const rowData = this.dataTruckType[index]
        if (flagCostStart == 0) {
          if (rowData.status == 'checked') {
            this.prices_pre_km = rowData.prices_pre_km
            this.prices_start = rowData.prices_start
            flagCostStart = 1
          }
        }
      }
      for (let index = 0; index < this.data_service_etc.length; index++) {
        const element = this.data_service_etc[index]
        if (element.service_sel_status == 'checked') {
          countService = countService + 100
        }
      }
      cost = this.prices_start + dRaw * this.prices_pre_km + countService
      this.totalCost = cost.toFixed(0)
      this.distanceCal = dRaw.toFixed(2)
    },
    calStandardCost() {
      // คำนวณราคาทั้งหมด
      this.distanceCal = 0
      this.prices_start = 0
      this.prices_pre_km = 0
      this.data_truck_type_standard_cost = []
      var dRaw = this.distanceRaw
      var cost = 0
      var countService = 0
      var dataObj = {}
      var data_not_checked = []
      var flag_repeat_price = 0
      var count_truck_type_sel = 0
      var truck_type_name_sel = ''
      var prices_pre_km = 0
      var prices_start = 0
      var tamp_prices_pre_km = 0
      var tamp_prices_start = 0
      var totalCost = 0
      var distanceCal = 0
      if (this.two_way_status == 'checked') {
        // เช็ค ไป - กลับ
        dRaw = dRaw * 2
      }
      for (let index = 0; index < this.data_service_etc.length; index++) {
        const element = this.data_service_etc[index]
        if (element.service_sel_status == 'checked') {
          countService = countService + 100
        }
      }
      for (let index2 = 0; index2 < this.dataTruckTypeGroup.length; index2++) {
        const rowGroupData = this.dataTruckTypeGroup[index2]
        truck_type_name_sel = '' // รีเซ็ตชื่อประเภทรถที่เลือก
        count_truck_type_sel = 0
        flag_repeat_price = 0
        var tamp_truck_type_name_sel = ''
        dataObj = {}
        var ck_flag = 0
        for (let index = 0; index < this.dataTruckType.length; index++) {
          // เช็คประเภทรถที่เลือก
          const rowData = this.dataTruckType[index]
          if (rowData.id_group == rowGroupData.id_group) {
            if (rowData.status == 'checked') {
              // เช็คประเภทรถในกลุ่มที่มีการเลือก
              if (count_truck_type_sel < 2) {
                // ถ้ามีการเลือกประเภทรถมากว่า 2 ประเภท
                if (truck_type_name_sel != '') {
                  truck_type_name_sel += ', '
                }
                if (this.goods_lang) {
                  truck_type_name_sel += rowData.name_model_th
                } else {
                  truck_type_name_sel += rowData.name_model_en
                }
                tamp_truck_type_name_sel = truck_type_name_sel
                count_truck_type_sel++
              } else {
                truck_type_name_sel = tamp_truck_type_name_sel + ', + ' + count_truck_type_sel
              }
              ck_flag = 1
            }
            if (flag_repeat_price == 0) {
              // เงื่อนไขเช็คราคาเริ่มต้น
              prices_pre_km = rowData.prices_pre_km
              prices_start = rowData.prices_start
              tamp_prices_pre_km = prices_pre_km
              tamp_prices_start = prices_start
              flag_repeat_price++
            } else {
              if (rowData.prices_pre_km < tamp_prices_pre_km) {
                prices_pre_km = rowData.prices_pre_km
                tamp_prices_pre_km = prices_pre_km
              }
              if (rowData.prices_start < tamp_prices_start) {
                prices_start = rowData.prices_start
                tamp_prices_start = prices_start
              }
            }
            cost = prices_start + dRaw * prices_pre_km + countService
            totalCost = cost.toFixed(0)
            distanceCal = dRaw.toFixed(2)
            this.distanceCal = distanceCal
          }
        }
        if (count_truck_type_sel == 0) {
          // เงื่อนไขถ้าเลือกประเภทรถมากกว่าที่กำหนดให้แสดงออกเป้นตัวเลข
          if (this.goods_lang) {
            truck_type_name_sel += rowGroupData.name_group_th
          } else {
            truck_type_name_sel += rowGroupData.name_group_en
          }
        }
        dataObj = {
          id_group: rowGroupData.id_group,
          name_group_th: rowGroupData.name_group_th,
          name_group_en: rowGroupData.name_group_en,
          truck_type_name_sel: truck_type_name_sel,
          prices_pre_km: prices_pre_km,
          prices_start: prices_start,
          total_cost: parseFloat(totalCost),
          distanceCal: parseFloat(distanceCal),
        }
        if (ck_flag == 1) {
          this.data_truck_type_standard_cost.push(dataObj)
        } else {
          data_not_checked.push(dataObj)
        }
      }
      if (this.data_truck_type_standard_cost.length > 0) {
        if (data_not_checked.length > 0) {
          for (let index = 0; index < data_not_checked.length; index++) {
            const element = data_not_checked[index]
            this.data_truck_type_standard_cost.push(element)
          }
        }
        // console.log(this.data_truck_type_standard_cost)
        this.budget_display = true
      } else {
        this.budget_display = false
      }
    },
    async activeLocation(index, data) {
      // เมนูเพิ่มเติมจุดรับสินค้า (เปิดปิดและเซ็ตค่าใน input box)
      if (this.pinPointLayerOnMap) {
        // this.popupTravelOnMapActiveStatus = true
        this.popupOnMapActiveStatus = false
        this.map.removeLayer(this.pinPointLayerOnMap)
        if (this.directions_data.route !== null && this.directions_data.route !== undefined) {
          this.calDirectionsHereMap()
        }
      }
      this.activeLocation_index = index
      this.activeLocation_data = data
      if (this.open_location_sel_status) {
        this.open_location_sel_status = false
        this.search_text = ''
      } else {
        this.open_location_sel_status = true
        this.search_text = this.data_location[this.activeLocation_index].texts
        await this.getFavoriteAndHistoryLocation()
        var that = this
        setTimeout(function () {
          that.search_location(that.search_text)
        }, 300)
      }
    },
    add_location(mode, index) {
      if (mode == 'add') {
        var new_location = {
          id: '',
          icon: 'ri-map-pin-fill',
          location: '',
          text: '',
          texts_en: '',
          LAT: '',
          LON: '',
          cood: null,
          iconLocation: null,
        }
        var new_location_time = {
          id: '',
          icon: 'ri-time-fill',
          date: '',
        }
        this.data_location.push(new_location)
        this.date_time_delivery.push(new_location_time)
        // console.log(this.data_location)
        // console.log(this.date_time_delivery)
      } else {
        this.data_location.splice(index, 1)
        this.date_time_delivery.splice(index, 1)
      }
    },
    timeout_search_location(text) {
      var that = this
      clearTimeout(that.debounce)
      this.debounce = setTimeout(function () {
        that.search_location(text)
      }, 500)
    },
    location_split(text) {
      var data_return = {}
      var condition_splice = ' '
      if (text.search(' ต.') != -1) {
        condition_splice = ' ต.'
      } else if (text.search(' แขวง')) {
        condition_splice = ' แขวง'
      }
      var data_splice = text.split(condition_splice)
      let addr = (data_splice[1] != undefined) ? data_splice[1] : ''
      if (addr != '') {
        addr = condition_splice + addr
      }
      data_return = {
        location_name: data_splice[0],
        location_addr: addr,
      }
      return data_return
    },
    location_split2(text) {
      var data_return = {}
      var condition_splice = ' '
      var data_splice = text.split(condition_splice)
      let location_name = ''
      let location_addr = ''
      let last_index = data_splice.length - 1
      let last_addr = data_splice[last_index]
      let last_index_forloop = 0
      if (parseInt(last_addr)) {
        location_addr = `${data_splice[last_index - 3]} ${data_splice[last_index - 2]} ${data_splice[last_index - 1]} ${data_splice[last_index]}`
        last_index_forloop = last_index - 4
      } else {
        location_addr = `${data_splice[last_index - 2]} ${data_splice[last_index - 1]} ${data_splice[last_index]}`
        last_index_forloop = last_index - 3
      }

      for (let index = 0; index <= last_index_forloop; index++) {
        if (location_name != '') {
          location_name += ' '
        }
        location_name += `${data_splice[index]}`
      }

      data_return = {
        location_name: location_name,
        location_addr: location_addr,
      }
      return data_return
    },
    select_location(data) {
      this.data_location[this.activeLocation_index].texts = data.texts
      this.data_location[this.activeLocation_index].texts_en = data.texts_en
      this.data_location[this.activeLocation_index].LON = data.LON
      this.data_location[this.activeLocation_index].LAT = data.LAT
      this.data_location[this.activeLocation_index].location_name = data.location_name
      this.data_location[this.activeLocation_index].location_addr = data.location_addr
      let coordinate = [parseFloat(data.LON), parseFloat(data.LAT)]
      this.setPinPointLocation(coordinate)
      var vue = this
      setTimeout(function () {
        vue.activeLocation()
      }, 300)
    },
    select_location2(data) {
      this.data_location[this.activeLocation_index].texts = data.texts
      this.data_location[this.activeLocation_index].texts_en = data.texts_en
      this.data_location[this.activeLocation_index].LON = data.lon
      this.data_location[this.activeLocation_index].LAT = data.lat
      this.data_location[this.activeLocation_index].location_name = data.location_name
      this.data_location[this.activeLocation_index].location_addr = data.location_addr
      let coordinate = [parseFloat(data.lon), parseFloat(data.lat)]
      this.setPinPointLocation(coordinate)
      var vue = this
      setTimeout(function () {
        vue.activeLocation()
      }, 300)
    },
    moveLayerBefore(old_idx, new_idx, layer) {
      if (old_idx === -1 || new_idx === -1) {
        return false
      }
      var vue = this
      // layer.removeAt(old_idx)
      layer = vue.map.getLayers().removeAt(old_idx)
      // layer = this.map
      this.map.getLayers().insertAt(new_idx, layer)
    },
    findLayer(layer_name) {
      var layer_idx = -1
      var layerArr = this.map.getLayers().getArray()
      for (let index = 0; index < layerArr.length; index++) {
        const element = layerArr[index]
        var this_layer_name = element.get('name')
        if (this_layer_name == layer_name) {
          layer_idx = index
        }
      }
      return layer_idx
    },
    async removeLayerByName(name) {
      var layer = this.map.getLayers().getArray()
      await this.map.removeLayer(layer[this.findLayer(name)])
    },
    getHeightDivMap() {
      var heightString = this.$refs.map.clientHeight - 22 + 'px'
      // console.log(heightString)
      this.heightMap = heightString
    },
    checkCurrentLocationPermission() {
      let location = window.location
      // console.log(location)
      if (location.protocol == 'https:') {
        this.current_location_permission_flag = true
      } else {
        if (location.hostname == 'localhost') {
          this.current_location_permission_flag = true
        } else {
          this.current_location_permission_flag = false
        }
      }
    },
    search_current_location() {
      if (navigator.geolocation) {
        var that = this
        navigator.geolocation.getCurrentPosition(
          position => {
            var coords = proj.fromLonLat([position.coords.longitude, position.coords.latitude])
            that.setPinPointCurrentLocation_onMap(coords)
          },
          error => {
            console.log(error.message)
          },
        )
      } else {
        console.log('Your borwser dose not support geolacation API. ')
      }
    },
    active_current_location() {
      if (!this.activeCurrentLocation_Status) {
        this.activeCurrentLocation_Status = true
        this.activeSetZoomAndCenterCurrentLocation = true
      } else {
        this.activeCurrentLocation_Status = false
        if (this.pinPointCurrentLayerOnMap) {
          this.map.removeLayer(this.pinPointCurrentLayerOnMap)
        }
        this.activeSetZoomAndCenterCurrentLocation = false
      }
      this.loop_search_current_location()
    },
    loop_search_current_location() {
      var that = this
      if (this.activeCurrentLocation_Status) {
        this.search_current_location()
        setTimeout(function () {
          that.loop_search_current_location()
        }, 5000)
      }
    },
    routersClick(link) {
      // eslint-disable-next-line no-undef
      this.$router.push({ path: link })
    },
    switchLocation() {
      // console.log(this.data_location)
      let location_array = this.data_location
      this.data_location = []
      for (let index = 0; index < location_array.length; index++) {
        if (index == 0) {
          const row = location_array[1]
          this.data_location.push(row)
        } else if (index == 1) {
          const row = location_array[0]
          this.data_location.push(row)
        }
      }
      this.switchPinPoint()
    },
    switchPinPoint() {
      let coordinate = []
      this.data_location[0].id = 1
      this.data_location[1].id = 2
      let location_1 = this.data_location[0]
      let location_2 = this.data_location[1]

      if (location_1.LON != '' && location_1.LAT != '') {
        this.activeLocation_index = 0
        coordinate = [parseFloat(location_1.LON), parseFloat(location_1.LAT)]
        this.setPinPointLocation(coordinate)
      }

      if (location_2.LON != '' && location_2.LAT != '') {
        this.activeLocation_index = 1
        coordinate = [parseFloat(location_2.LON), parseFloat(location_2.LAT)]
        this.setPinPointLocation(coordinate)
      }
    },
    addFavoriteLocation(data, index) {
      // console.log(data)
      var that = this
      clearTimeout(that.debounce2)

      let search_result_data = this.search_result_data
      this.search_result_data = []
      for (let index2 = 0; index2 < search_result_data.length; index2++) {
        let row = search_result_data[index2]
        if (index2 == index) {
          if (row.favorite_flg == 0 || row.favorite_flg == 2) {
            row.favorite_flg = 1
            row.favorite_flg_time = moment().format('YYYY-MM-DD HH:mm:ss')
          } else if (row.favorite_flg == 1) {
            row.favorite_flg = 2
            row.favorite_flg_time = moment().format('YYYY-MM-DD HH:mm:ss')
          } else {
            row.favorite_flg = 0
            row.favorite_flg_time = moment().format('YYYY-MM-DD HH:mm:ss')
          }
        }
        this.search_result_data.push(row)
      }

      this.debounce2 = setTimeout(function () {
        that.addFavoriteLocationService()
      }, 1000)
    },
    addFavoriteLocationService() {
      service
        .postService('addFavoriteLocation', {
          search_result_data: this.search_result_data,
          account_data: this.account_data,
        })
        .then(rp => {
          if (rp.data != undefined) {
            if (rp.status != 'ok') {
              // console.log(rp)
            }
          }
        })
    },
    getFavoriteAndHistoryLocation() {
      this.current_favorite_location_data = []
      this.location_previous_plans_data = []
      service
        .postService('getFavoriteAndHistoryLocation', {
          account_data: this.account_data,
        })
        .then(rp => {
          // console.log(rp)
          if (rp.status == 'ok') {
            let data = rp.data
            this.current_favorite_location_data = data.current_favorite_location_data
            this.location_previous_plans_data = data.location_previous_plans_data
            this.createLocationPrevious()
          }
        })
    },
    createLocationPrevious() {
      // arrival_address: (...)
      // arrival_lat_lon: (...)
      // departure_address: (...)
      // departure_lat_lon: (...)
      let data = this.location_previous_plans_data
      this.location_previous_plans_data = []
      for (let index = 0; index < data.length; index++) {
        const element = data[index]
        let addr = ''
        let lat_lon = []
        if (this.activeLocation_index == 0) {
          addr = element.departure_address
          lat_lon = element.departure_lat_lon.split('|')
        } else {
          addr = element.arrival_address
          lat_lon = element.arrival_lat_lon.split('|')
        }
        let location_split = this.location_split2(addr)
        let obj = {
          lat: lat_lon[0],
          lon: lat_lon[1],
          location_name: location_split.location_name,
          location_addr: location_split.location_addr,
          texts: addr,
          texts_en: addr,
        }
        this.location_previous_plans_data.push(obj)
      }
    },
    deleteFavoriteLocation(index_delete) {
      let location_data = this.current_favorite_location_data
      // console.log(location_data)
      this.current_favorite_location_data = []
      for (let index = 0; index < location_data.length; index++) {
        const element = location_data[index]
        if (index == index_delete) {
          this.deleteFavoriteLocationService(element)
        } else {
          this.current_favorite_location_data.push(element)
        }
      }
    },
    deleteFavoriteLocationService(location_data) {
      // console.log(location_data)
      service
        .postService('deleteFavoriteLocation', {
          account_data: this.account_data,
          location_data: location_data,
        })
        .then(rp => {
          if (rp.status != 'ok') {
            // console.log(rp)
          }
        })
    },
    // goods part
    modals_active() {
      if (this.open_goods_type_status) {
        if (this.modals_map) {
          this.activeGoodsType()
        }
      }
      if (this.open_truck_type_status) {
        if (this.modals_map) {
          this.activeTruckType()
        }
      }
      if (this.open_service_sel_status) {
        if (this.modals_map) {
          this.activeServiceEtc()
        }
      }
    },
    getDataGoodsType() {
      // get ข้อมูล ประเภทสินค้า
      // console.log(this.account_data)
      let account_data = this.account_data[0]
      let goodsTypeData = account_data.goods_type
      let goodsTypeDataObj = goodsTypeData.split(',')
      var that = this
      service.postService('getGoodsType').then(rp => {
        // console.log(rp)
        let data = rp.data
        for (let index = 0; index < data.length; index++) {
          const element = data[index]
          let goodsTypeUserStatus = ''
          for (let index2 = 0; index2 < goodsTypeDataObj.length; index2++) {
            const element2 = goodsTypeDataObj[index2]
            if (element.goods_type_id == element2) {
              goodsTypeUserStatus = 'checked'
              this.dataGoodsType_note = account_data.goods_type_note
            }
          }
          var goods_type_sel = {
            goods_type_id: data[index]['goods_type_id'],
            goods_type_name_th: data[index]['goods_type_name_th'],
            goods_type_name_en: data[index]['goods_type_name_en'],
            goods_type_status: goodsTypeUserStatus,
          }
          this.dataGoodsType_master.push(goods_type_sel) // for insert data to db
          this.dataGoodsType_data.push(goods_type_sel)
          if (this.account_lang != 'th') {
            this.goods_lang = false
          }
        }
        // console.log(this.dataGoodsType_data2)
        that.goods_type_sel()
      })
    },
    activeGoodsType() {
      // เมนูเพิ่มเติม ประเภทสินค้า
      if (this.open_truck_type_status) {
        this.open_truck_type_status = false
      }
      if (this.open_service_sel_status) {
        this.open_service_sel_status = false
      }
      if (this.open_goods_type_status) {
        this.open_goods_type_status = false
        this.modals_map = false
      } else {
        this.open_goods_type_status = true
        this.modals_map = true
      }
    },
    // eslint-disable-next-line no-unused-vars
    goods_type_sel(data) {
      // console.log(data)
      // console.log(this.dataGoodsType_data)
      this.data_goods_type_sel = ''
      this.data_goods_type_sel_full = ''
      var countTypeSelect = 0
      var temp_data_goods_type_sel = ''
      for (let index = 0; index < this.dataGoodsType_data.length; index++) {
        const element = this.dataGoodsType_data[index]
        if (element.goods_type_status == 'checked') {
          if (this.data_goods_type_sel != '') {
            if (countTypeSelect < 2) {
              this.data_goods_type_sel += ', '
              this.data_goods_type_sel_full += ', '
              if (!this.goods_lang) {
                this.data_goods_type_sel += element.goods_type_name_en
              } else {
                this.data_goods_type_sel += element.goods_type_name_th
              }
              if (element.goods_type_name_th == 'อื่นๆ') {
                if (this.dataGoodsType_note != '') {
                  this.data_goods_type_sel += ' (' + this.dataGoodsType_note + ')'
                }
              }
              temp_data_goods_type_sel += this.data_goods_type_sel
            } else {
              this.data_goods_type_sel = temp_data_goods_type_sel + ', +' + (countTypeSelect - 1)
            }
          } else {
            if (!this.goods_lang) {
              this.data_goods_type_sel = element.goods_type_name_en
            } else {
              this.data_goods_type_sel = element.goods_type_name_th
            }
            if (element.goods_type_name_th == 'อื่นๆ') {
              if (this.dataGoodsType_note != '') {
                this.data_goods_type_sel += ' (' + this.dataGoodsType_note + ')'
              }
            }
          }
          if (!this.goods_lang) {
            this.data_goods_type_sel_full += element.goods_type_name_en
          } else {
            this.data_goods_type_sel_full += element.goods_type_name_th
          }
          if (element.goods_type_name_th == 'อื่นๆ') {
            if (this.dataGoodsType_note != '') {
              this.data_goods_type_sel_full += ' (' + this.dataGoodsType_note + ')'
            }
          }
          countTypeSelect++
        }
      }
    },
    // Truck type part
    getTruckType() {
      var that = this
      service.postService('getTruckType').then(rp => {
        // console.log(rp)
        var data = JSON.parse(JSON.stringify(rp.data))
        // console.log(data)
        for (let index = 0; index < data.length; index++) {
          let row = data[index]
          let rowdata = {
            id_group: row.id_group,
            id_model: row.id_model,
            name_group_en: row.name_group_en,
            name_group_th: row.name_group_th,
            name_model_en: row.name_model_en,
            name_model_th: row.name_model_th,
            other_flag: row.other_flag,
            prices_pre_km: row.prices_pre_km,
            prices_start: row.prices_start,
            status: '',
          }
          this.dataTruckType.push(JSON.parse(JSON.stringify(rowdata)))
          that.dataTruckType_ck[index] = ''
        }
        // console.log(that.dataTruckType)
      })
    },
    getTruckTypeGroup() {
      var that = this
      service.postService('getTruckTypeGroup').then(rp => {
        // console.log(rp)
        var data = rp.data
        for (let index = 0; index < data.length; index++) {
          var row = data[index]
          let rowdata = {
            id_group: row.id_group,
            name_group_en: row.name_group_en,
            name_group_th: row.name_group_th,
            status: '',
          }
          that.dataTruckTypeGroup.push(JSON.parse(JSON.stringify(rowdata)))
          that.dataTruckTypeGroup_ck[index] = ''
        }
        // console.log(that.dataTruckTypeGroup)
      })
    },
    activeTruckType() {
      if (this.open_goods_type_status) {
        this.open_goods_type_status = false
      }
      if (this.open_location_sel_status) {
        this.open_location_sel_status = false
      }
      if (this.open_service_sel_status) {
        this.open_service_sel_status = false
      }
      if (this.open_truck_type_status) {
        this.open_truck_type_status = false
        this.modals_map = false
      } else {
        this.open_truck_type_status = true
        this.modals_map = true
      }
    },
    // eslint-disable-next-line no-unused-vars
    truck_type_sel(data) {
      // console.log(data)
      // console.log(id)
      // console.log(this.dataTruckType)
      this.data_truck_type_sel = []
      for (let index = 0; index < this.dataTruckType.length; index++) {
        const element = this.dataTruckType[index]
        var truck_type_name = {
          name: '',
          id_model: '',
        }
        if (element.status == 'checked') {
          if (this.goods_lang) {
            truck_type_name.name = element.name_model_th
          } else {
            truck_type_name.name = element.name_model_en
          }
          truck_type_name.id_model = element.id_model
          this.data_truck_type_sel.push(truck_type_name)
          this.dataTruckTypeGroup[element.id_group - 1].status = 'checked'
        }
      }
      this.checkTruckTypeInGroup(data)
      this.createTextInputTruckType()
    },
    checkTruckTypeInGroup(data) {
      var check_id_group = data.id_group
      var checkCount = 0
      for (let index = 0; index < this.dataTruckType.length; index++) {
        const element = this.dataTruckType[index]
        if (element.id_group == check_id_group) {
          if (element.status == 'checked') {
            checkCount = 1
          }
        }
      }
      if (checkCount == 0) {
        this.dataTruckTypeGroup[check_id_group - 1].status = ''
      }
    },
    createTextInputTruckType() {
      this.data_truck_type_sel_txt = ''
      var indexCount = 0
      if (this.data_truck_type_sel.length > 0) {
        for (let index = 0; index < this.data_truck_type_sel.length; index++) {
          const element = this.data_truck_type_sel[index]
          if (index < 2) {
            if (this.data_truck_type_sel_txt == '') {
              this.data_truck_type_sel_txt = element.name
            } else {
              this.data_truck_type_sel_txt += ', '
              this.data_truck_type_sel_txt += element.name
            }
          } else {
            indexCount++
          }
        }
        if (indexCount > 0) {
          this.data_truck_type_sel_txt += ', + ' + indexCount
        }
      }
      // this.calTotalServiceCost()
      this.calStandardCost()
    },
    truck_type_group_sel(data, id) {
      // console.log(data)
      // console.log(id)
      // console.log(this.dataTruckTypeGroup)
      if (this.dataTruckTypeGroup[id].status == 'checked') {
        if (this.goods_lang) {
          this.data_truck_type_group_sel = data.name_group_th
        } else {
          this.data_truck_type_group_sel = data.name_group_en
        }
        this.selectAllTruckTypeFromGroup(id, 'select')
      } else {
        this.data_truck_type_group_sel = ''
        this.selectAllTruckTypeFromGroup(id, 'deselect')
      }
      // this.data_truck_type_group_sel_id = data.id_group
      // this.calTotalServiceCost()
    },
    selectAllTruckTypeFromGroup(id, mode) {
      // เลือกประเภทรถในกลุ่มรถทั้งหมด
      var id_group_data = id + 1
      var select_mode = mode
      var data = this.dataTruckType
      if (select_mode == 'select') {
        for (let index = 0; index < data.length; index++) {
          const element = data[index]
          if (element.id_group == id_group_data) {
            if (this.dataTruckType[index].other_flag != '1') {
              this.dataTruckType[index].status = 'checked'
            }
          }
        }
      } else {
        for (let index2 = 0; index2 < data.length; index2++) {
          const rowData = data[index2]
          if (rowData.id_group == id_group_data) {
            this.dataTruckType[index2].status = ''
          }
        }
      }
      // this.createTextInputTruckType()
      this.truck_type_sel(this.dataTruckTypeGroup[id])
    },
    // select service
    getServiceEtc() {
      // console.log(this.data_service_etc)
      this.data_service_sel = ''
      this.data_service_sel_full = ''
      var count_select = 0
      var temp_select = ''
      for (let index = 0; index < this.data_service_etc.length; index++) {
        const element = this.data_service_etc[index]
        if (element.service_sel_status == 'checked') {
          if (this.data_service_sel != '') {
            if (count_select < 2) {
              this.data_service_sel += ', '
              if (!this.goods_lang) {
                this.data_service_sel += element.name_en
              } else {
                this.data_service_sel += element.name_th
              }
              if (element.name_th == 'อื่นๆ') {
                if (this.data_service_ck_note != '') {
                  this.data_service_sel += ' (' + this.data_service_ck_note + ')'
                }
              }
              temp_select += this.data_service_sel
            } else {
              this.data_service_sel = temp_select + ', +' + (count_select - 1)
            }
          } else {
            if (!this.goods_lang) {
              this.data_service_sel += element.name_en
            } else {
              this.data_service_sel = element.name_th
            }
            if (element.name_th == 'อื่นๆ') {
              if (this.data_service_ck_note != '') {
                this.data_service_sel += ' (' + this.data_service_ck_note + ')'
              }
            }
          }
          if (this.data_service_sel_full != '') {
            this.data_service_sel_full += ', '
          }
          if (!this.goods_lang) {
            this.data_service_sel_full += element.name_en
          } else {
            this.data_service_sel_full += element.name_th
          }
          if (element.name_th == 'อื่นๆ') {
            if (this.data_service_ck_note != '') {
              this.data_service_sel_full += ' (' + this.data_service_ck_note + ')'
            }
          }
          count_select++
        }
      }
      // this.calTotalServiceCost()
      this.calStandardCost()
    },
    activeServiceEtc() {
      if (this.open_location_sel_status) {
        this.open_location_sel_status = false
      }
      if (this.open_truck_type_status) {
        this.open_truck_type_status = false
      }
      if (this.open_service_sel_status) {
        this.open_service_sel_status = false
        this.modals_map = false
      } else {
        this.open_service_sel_status = true
        this.modals_map = true
      }
    },
    // eslint-disable-next-line no-unused-vars
    service_etc_sel(data) {
      // console.log(data)
      // console.log(this.dataGoodsType_data)
      this.data_goods_type_sel = ''
      for (let index = 0; index < this.dataGoodsType_data.length; index++) {
        const element = this.dataGoodsType_data[index]
        if (element.goods_type_status == 'checked') {
          if (this.data_goods_type_sel != '') {
            this.data_goods_type_sel += ', '
            this.data_goods_type_sel += element.goods_type_name_th
          } else {
            this.data_goods_type_sel = element.goods_type_name_th
          }
        }
      }
    },
    // cal cost transport
    active_all_cost_display() {
      if (this.budget_display) {
        if (!this.all_cost_display) {
          this.all_cost_display = true
          this.all_cost_btn = true
        } else {
          this.all_cost_display = false
          this.all_cost_btn = false
        }
      }
    },
    checkAllDataInput() {
      // เช็คข้อมูลทั้งหมดก่อนสร้างแผนงานขนส่ง
      // this.activeSummaryInfo()
      var ck_goods_type = false
      var ck_tons = false
      var ck_location = false
      var ck_location_time = false
      var ck_truck_type = false
      var ck_totalCost = false
      var ck_timeRange = false
      // เช็คประเภทสินค้า
      if (this.dataGoodsType_data != '' && this.dataGoodsType_data != null) {
        for (let index = 0; index < this.dataGoodsType_data.length; index++) {
          const element = this.dataGoodsType_data[index]
          if (element.goods_type_status == 'checked') {
            ck_goods_type = true
          }
        }
      }
      // เช็คน้ำหนักสินค้า
      if (typeof this.data_goods_tons === 'number') {
        this.data_goods_tons = parseFloat(this.data_goods_tons.replace(',', ''))
      }
      if (this.data_goods_tons > 0) {
        ck_tons = true
      }
      // เช็คจุด รับ - ส่งสินค้า
      if (this.data_location.length != null && this.data_location.length != undefined && this.data_location.length > 0) {
        for (let index2 = 0; index2 < this.data_location.length; index2++) {
          const element2 = this.data_location[index2]
          if (element2.location_name != null && element2.location_name != '' && element2.location_name != undefined) {
            ck_location = true
          }
        }
      }
      // เช็คเวลา รับ - ส่งสินค้า
      if (this.date_time_delivery.length != null && this.date_time_delivery.length != undefined && this.date_time_delivery.length > 0) {
        this.date_time_delivery_new = []
        for (let index3 = 0; index3 < this.date_time_delivery.length; index3++) {
          const element3 = this.date_time_delivery[index3]
          if (element3.date != null && element3.date != '' && element3.date != undefined) {
            ck_location_time = true
            this.date_time_delivery_new.push(moment(element3.date).format('YYYY-MM-DD HH:mm:ss')) // แปลง format เวลารับ ส่ง สินค้า
          }
        }
      }
      if (this.allDay_status != 'checked') {
        if (this.time_delivery !== undefined && this.time_delivery !== null) {
          if (this.time_delivery.length > 0) {
            ck_timeRange = true
          }
        }
      } else {
        ck_timeRange = true
      }
      // เช็คประเภทรถ
      if (this.dataTruckType != null && this.dataTruckType != '' && this.dataTruckType != undefined) {
        ck_truck_type = true
      }

      if (this.totalCost > 0 && this.totalCost != '') {
        ck_totalCost = true
      }
      // console.log('ข้อมูลประเภทสินค้า')
      // console.log(this.dataGoodsType_data)
      // console.log(this.data_goods_type_sel)
      // console.log(this.dataGoodsType_etc)
      // console.log(this.dataGoodsType_note)
      // console.log(this.data_goods_tons)
      // console.log('----------------------')
      // console.log('ข้อมูลสถานที่')
      // console.log(this.data_location)
      // console.log(this.date_time_delivery)
      // console.log('----------------------')
      // console.log('ข้อมูลประเภทรถ')
      // console.log(this.dataTruckType)
      // console.log(this.data_service_etc)
      // console.log(this.data_service_sel)
      // console.log(this.data_service_ck_note)
      // console.log(this.totalCost)
      // console.log('----------------------')

      // var date_time_delivery_new = []
      // for (let index = 0; index < this.date_time_delivery.length; index++) {
      //   const element = this.date_time_delivery[index]
      //   if(element.date != '' && element.date != null && element.date != undefined) {
      //     date_time_delivery_new.push(moment(element.date).format('YYYY-MM-DD HH:mm:ss'))
      //   }
      // }
      // console.log(date_time_delivery_new)
      if (ck_goods_type && ck_tons && ck_location && ck_location_time && ck_truck_type && ck_timeRange) {
        if (!ck_totalCost) {
          this.totalCost = this.data_truck_type_standard_cost[0].total_cost
        }
        // console.log('pass')
        this.activeSummaryInfo()
      } else {
        var msg = ''
        msg += '<b-row>'
        msg += '<b-col>'
        msg += '<span>'
        if (!ck_goods_type) {
          msg += this.lang.msg.goods_type + ' <br/>'
        }
        if (!ck_tons) {
          msg += this.lang.msg.tons + ' <br/>'
        }
        if (!ck_location) {
          msg += this.lang.msg.location + ' <br/>'
        }
        if (!ck_location_time) {
          msg += this.lang.msg.time + ' <br/>'
        }
        if (!ck_truck_type) {
          msg += this.lang.msg.truck_type + ' <br/>'
        }
        if (!ck_timeRange) {
          msg += this.lang.msg.pickup_time + ' <br/>'
        }
        msg += '</span>'
        msg += '</b-col>'
        msg += '</b-row>'
        global_func.alertMessage('', msg, 'warning')
      }
    },
    activeSummaryInfo() {
      if (this.summary_info_plan_status) {
        this.summary_info_plan_status = false
      } else {
        this.summary_info_plan_status = true
      }
    },
    checkDeadlineSelcet() {
      if (this.deadline_selected != '') {
        this.savePlanData()
        // this.send_sp_offer_plan()
      } else {
        let msg = this.lang.msg.deadline_select
        global_func.alertMessage('', msg, 'warning')
      }
    },
    // save data part
    savePlanData() {
      if (!this.data_goods_weight_select_flg) {
        // convert weight kg to tons
        let tons_input = this.data_goods_tons
        let kg_cal = tons_input / 1000
        this.data_goods_tons = kg_cal.toFixed(2)
      }
      service
        .postService('saveTransporterPlan', {
          dataGoodsType_data: this.dataGoodsType_data,
          dataGoodsType_etc: this.dataGoodsType_etc,
          dataGoodsType_note: this.dataGoodsType_note,
          data_goods_tons: this.data_goods_tons,
          data_location: this.data_location,
          date_time_delivery_new: this.date_time_delivery_new,
          data_truck_type_sel: this.data_truck_type_sel,
          dataTruckType: this.dataTruckType,
          data_truck_type_sel_id: this.data_truck_type_sel_id,
          dataTruckType_note: this.dataTruckType_note,
          data_service_sel: this.data_service_sel,
          data_service_etc: this.data_service_etc,
          data_service_ck_note: this.data_service_ck_note,
          prices_pre_km: this.prices_pre_km,
          prices_start: this.prices_start,
          distanceCal: this.distanceCal,
          totalCost: this.totalCost,
          account_data: this.account_data,
          deadline: this.deadline_selected,
          allDay_status: this.allDay_status,
          time_delivery: this.time_delivery,
          data_offers_allowed: this.data_offers_allowed,
        })
        .then(rp => {
          if (this.sp_send_offer_flag == 'checked') {
            this.send_sp_offer_plan(rp.data)
          } else {
            this.confirmAlert()
          }
        })
    },
    confirmAlert() {
      Swal.fire({
        title: 'สร้างแผนงานเรียบร้อย',
        text: 'เราจะนำคุณไปหน้า “แผนงานขนส่ง”',
        icon: 'success',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        // cancelButtonColor: '#FFFFFF',
        // cancelButtonTextColor: '#343C48',
        confirmButtonText: 'ไปหน้า “แผนงานขนส่ง”',
        cancelButtonText: 'ให้ฉันอยู่หน้านี้ต่อไป',
      }).then(result => {
        if (result.value) {
          this.$router.push({ path: '/transportation_plan/plans_display' })
        } else {
          window.location.reload()
        }
      })
    },
    // format and convert data
    formatDistance(value) {
      let val = (value / 1).toFixed(1).replace('.', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace('.', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    convertWeight() {
      let tons_input = parseFloat(this.data_goods_tons)
      // console.log(new Intl.NumberFormat('th-IN', { maximumSignificantDigits: 2 }).format(tons_input))
      if (this.data_goods_weight_select_flg) {
        let tons_cal = tons_input * 1000
        this.data_goods_tons = tons_cal.toFixed(2)
        this.data_goods_weight_select_flg = false
        this.data_goods_weight_text = this.lang_no1.kg
      } else {
        let kg_cal = tons_input / 1000
        this.data_goods_tons = kg_cal.toFixed(2)
        this.data_goods_weight_select_flg = true
        this.data_goods_weight_text = this.lang_no1.tons
      }
    },
    // create sp plan
    check_sp_plan_data() {
      let data = sessionStorage.getItem('specific_goods_plan')
      // console.log(data)
      this.sp_plan_data = JSON.parse(data)
      // console.log(this.sp_plan_data)
      sessionStorage.removeItem('specific_goods_plan')
      if (this.sp_plan_data != null && this.sp_plan_data != undefined) {
        let that = this
        setTimeout(function () {
          that.createSpPlan()
        }, 100)
      }
    },
    async createSpPlan() {

      let location_data_sp = this.sp_plan_data.rawData

      // set location 1
      this.activeLocation_index = 0
      let data = await this.getLocationSpPlan(this.activeLocation_index, location_data_sp)
      this.data_location[this.activeLocation_index].texts = data.texts
      this.data_location[this.activeLocation_index].texts_en = data.texts_en
      this.data_location[this.activeLocation_index].LON = data.lon
      this.data_location[this.activeLocation_index].LAT = data.lat
      this.data_location[this.activeLocation_index].location_name = data.location_name
      this.data_location[this.activeLocation_index].location_addr = data.location_addr
      let coordinate = [parseFloat(data.lon), parseFloat(data.lat)]
      await this.setPinPointLocation(coordinate)

      // set location 2
      this.activeLocation_index = 1
      let data2 = await this.getLocationSpPlan(this.activeLocation_index, location_data_sp)
      this.data_location[this.activeLocation_index].texts = data2.texts
      this.data_location[this.activeLocation_index].texts_en = data2.texts_en
      this.data_location[this.activeLocation_index].LON = data2.lon
      this.data_location[this.activeLocation_index].LAT = data2.lat
      this.data_location[this.activeLocation_index].location_name = data2.location_name
      this.data_location[this.activeLocation_index].location_addr = data2.location_addr
      let coordinate2 = [parseFloat(data2.lon), parseFloat(data2.lat)]
      await this.setPinPointLocation(coordinate2)

      // set date 
      let depart_date = new Date(location_data_sp.depart_date)
      let date_now = new Date
      if (depart_date >= date_now) {
        this.date_time_delivery[0].date = depart_date
      }

      // set truck type
      if (this.dataTruckType.length > 0) {
        // console.log(this.dataTruckType)
        let truck_type_id = location_data_sp.vehicle_type_id - 1
        this.dataTruckType[truck_type_id].status = 'checked'
        let id_group = {
          id_group: location_data_sp.vehicle_type_group_id,
        }
        this.truck_type_sel(id_group)
      }

      // sp plan flag
      this.sp_create_flag = 1
      this.sp_send_offer_flag = 'checked'

    },
    async getLocationSpPlan(index, location_data_sp) {
      let location_data = location_data_sp
      let location_split = []
      let lat_lon = []
      let texts = ''
      if (index == 0) {
        location_split = await this.location_split2(location_data.depart_address1)
        lat_lon = location_data.departure_lat_lon.split('|')
        texts = location_data.departure_address1
      } else {
        location_split = await this.location_split2(location_data.arrive_address1)
        lat_lon = location_data.arrival_lat_lon.split('|')
        texts = location_data.arrive_address1
      }
      let data_return = {
        lat: lat_lon[0],
        lon: lat_lon[1],
        location_name: location_split.location_name,
        location_addr: location_split.location_addr,
        texts: texts,
        texts_en: texts,
      }
      return data_return
    },
    send_sp_offer_plan(data) {
      service
        .postService('sendSpPlanOffer', {
          id_order_tracking: data.id_order_tracking,
          provider_id: this.sp_plan_data.rawData.id_member,
        })
        .then(rp => {
          // console.log(rp)
          if (rp.message == 'ok') {
            this.confirmAlert()
          }
        })
    },
  },
}
</script>
<template>
  <Layout>
    <!-- <PageHeader :title="title" :items="items" /> -->
    <b-row>
      <div v-if="open_goods_type_status || open_truck_type_status || open_service_sel_status" class="col-main-menu-tran"
           :class="{ modals_active: open_goods_type_status || open_truck_type_status || open_service_sel_status }"
           @click="modals_active()"
      />
      <b-col lg="3" class="col-main-menu-tran h-100vh main_menu_plan">
        <div v-show="sp_create_flag == 1" style="background-color: #FAAB00; padding: 2% 2% 2% 20px">
          <div style="padding: 0% 0% 0% 4%">
            <b-row>
              <b-col lg="1">
                <img src="@/assets/images/transportation_plan/Exclusion.png" height="25" />
              </b-col>
              <b-col>
                <span style="color: #FFFFFF;">
                  คุณกำลังสร้าง "แผนงานเฉพาะ"
                </span>
              </b-col>
            </b-row>
          </div>
        </div>
        <div class="card">
          <simplebar class="card-body" style="max-height: calc(100vh - 141px); min-height: calc(100vh - 141px);">
            <div style="padding: 4%;">
              <b-row class="col-margin-bot-1">
                <b-col lg="1">
                  <div class="number-blue d-flex justify-content-center">1</div>
                </b-col>
                <b-col>
                  <span class="d-flex text-middle">{{ lang_no1.title }}</span>
                </b-col>
              </b-row>
              <b-row class="col-margin-bot-2">
                <b-col lg="12">
                  <div class="form-group" @click="activeGoodsType()">
                    <b-form-input v-model="data_goods_type_sel" type="text" class="form-control grey_hover"
                                  name="firstname" :placeholder="lang_no1.placeholder.goods_type" disabled
                    />
                  </div>
                </b-col>
                <b-col lg="12">
                  <div class="form-group">
                    <div class="input-group prepend">
                      <!-- <b-form-input v-if="data_goods_weight_select_flg" id="payload" v-model.lazy="data_goods_tons" v-money="money" type="text" class="add_car form-control" :placeholder="lang_no1.placeholder.goods_tons" /> -->
                      <!-- <b-form-input v-else id="payload" v-model.lazy="data_goods_kg" v-money="money2" type="text" class="add_car form-control" :placeholder="lang_no1.placeholder.goods_tons" /> -->
                      <VuetifyMoney v-model="data_goods_tons" :placeholder="add_tons_placeholder" :readonly="readonly5"
                                    :disabled="disabled5" :outlined="outlined5" :clearable="clearable5" :options="options5"
                      />
                      <div class="input-group-prepend" @click="convertWeight()">
                        <span class="input-group-text custom-input-group-prepend-text" style="cursor: default;">{{
                          data_goods_weight_text
                        }}</span>
                      </div>
                    </div>
                  </div>
                </b-col>
                <b-col lg="12">
                  <div class="form-group">
                    <b-form-input v-model="dataGoodsType_etc" type="text" class="form-control"
                                  :placeholder="lang_no1.placeholder.detail"
                    />
                  </div>
                </b-col>
              </b-row>
              <b-row class="col-margin-bot-1">
                <b-col lg="1">
                  <div class="number-blue d-flex justify-content-center">2</div>
                </b-col>
                <b-col>
                  <span class="d-flex text-middle">{{ lang_no2.title }}</span>
                </b-col>
              </b-row>
              <b-row class="col-margin-bot-2">
                <b-col lg="12">
                  <ul class="list-unstyled activity-wid">
                    <li v-for="(data, index) in data_location" :key="index" class="activity-list">
                      <b-row>
                        <div class="activity-icon avatar-xs">
                          <span v-if="index > 0" class="avatar-title bg-soft-primary rounded-circle"
                                style="color: #B0BAC4"
                          >
                            <i :class="`${data.icon}`" />
                          </span>
                          <span v-else class="avatar-title bg-soft-primary text-primary rounded-circle">
                            <i :class="`${data.icon}`" />
                          </span>
                        </div>
                        <b-col>
                          <b-row>
                            <b-col lg="11">
                              <div v-if="data.id == 1" @click="activeLocation(index, data)">
                                <b-form-input v-model="data_location[index].location_name" type="text"
                                              class="form-control"
                                              :placeholder="`${lang_no2.placeholder.point + ' ' + (index + 1) + ' - ' + lang_no2.placeholder.pick_up}`"
                                              disabled @change="getKmFromLatLot()"
                                />
                              </div>
                              <div v-else @click="activeLocation(index, data)">
                                <b-form-input v-model="data_location[index].location_name" type="text"
                                              class="form-control"
                                              :placeholder="`${lang_no2.placeholder.point + ' ' + (index + 1) + ' - ' + lang_no2.placeholder.deliver}`"
                                              disabled @change="getKmFromLatLot()"
                                />
                              </div>
                            </b-col>
                            <b-col lg="1" class="d-flex align-items-center">
                              <img v-if="data.id == 1" src="@/assets/images/transportation_plan/sort@2x.png" height="20"
                                   style="cursor: pointer;" @click="switchLocation()"
                              />
                            </b-col>
                          </b-row>
                        </b-col>
                        <!-- func เพิ่มสถาที่ รับ - ส่ง สินค้า -->
                        <!-- <b-col md="1" class="margin-top-bot-auto d-flex justify-content-center btn_add_location">
                          <div v-if="data.id == 1">
                            <img src="@/assets/images/transportation_plan/sort@2x.png" height="20" />
                          </div>
                          <div v-else-if="index != Object.keys(data_location).length - 1 && index > 0" @click="add_location('del', index)">
                            <img src="@/assets/images/transportation_plan/Group 3752.svg" height="20" />
                          </div>
                          <div v-else class="margin-top-bot-auto d-flex justify-content-center btn_add_location" @click="add_location('add', index)">
                            <img src="@/assets/images/transportation_plan/add@2x.png" height="20" />
                          </div>
                        </b-col> -->
                      </b-row>
                    </li>
                  </ul>
                </b-col>
                <b-col lg="12">
                  <b-row>
                    <b-col>
                      <b-form-checkbox v-model="two_way_status" value="checked" name="checkbox" class="check-col"
                                       @change="calStandardCost()"
                      >
                        <template>{{ lang_no2.goback }}</template>
                        <br />
                        <template>
                          <span style="color:#B0BAC4; font-weight:300;">{{ lang_no2.goback_detail }}</span>
                        </template>
                      </b-form-checkbox>
                    </b-col>
                    <b-col lg="1" class="d-flex justify-content-center">
                      <div class="decoration-inside" />
                    </b-col>
                    <b-col>
                      <b-row>
                        <img src="@/assets/images/transportation_plan/distance.png" height="20" />
                        <div style="margin-left: 0.4rem">
                          <span style="font-weight:700;">
                            {{ lang_no2.dis }}
                          </span>
                          <br />
                          <span v-if="distanceCal > 0" class="font-color-blue">{{ formatDistance(distanceCal) }} {{
                            lang_g.km
                          }}</span>
                          <span v-else class="font-color-gray">-</span>
                        </div>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row class="col-margin-bot-1">
                <b-col lg="1">
                  <div class="number-blue d-flex justify-content-center">3</div>
                </b-col>
                <b-col>
                  <span class="d-flex text-middle">{{ lang_no3.title }}</span>
                  <span class="d-flex text-middle text-sub">{{ lang_no3.sub_title }}</span>
                </b-col>
              </b-row>
              <b-row class="col-margin-bot-1">
                <b-col lg="12">
                  <!-- <ul class="list-unstyled activity-wid">
                    <li v-for="(data, index) in date_time_delivery" :key="index" class="activity-list">
                      <b-row>
                        <div class="activity-icon avatar-xs">
                          <span v-if="index > 0" class="avatar-title bg-soft-primary rounded-circle" style="color:#B0BAC4;">
                            <i :class="`${data.icon}`" />
                          </span>
                          <span v-else class="avatar-title bg-soft-primary text-primary rounded-circle">
                            <i :class="`${data.icon}`" />
                          </span>
                        </div>
                        <b-col>
                          <div>
                            <date-picker v-if="index < 1" v-model="date_time_delivery[index].date" format="DD-MM-YYYY HH:mm" type="datetime" :placeholder="`${lang_no3.placeholder.point + ' ' + (index + 1) + ' ' + lang_no3.placeholder.datetime + ' - ' + lang_no3.placeholder.pick_up}`" />
                            <date-picker v-else v-model="date_time_delivery[index].date" format="DD-MM-YYYY HH:mm" type="datetime" :placeholder="`${lang_no3.placeholder.point + ' ' + (index + 1) + ' ' + lang_no3.placeholder.datetime + ' - ' + lang_no3.placeholder.deliver}`" />
                          </div>
                        </b-col>
                      </b-row>
                    </li>
                  </ul> -->
                  <date-picker v-model="date_time_delivery[0].date" format="DD-MM-YYYY" type="date"
                               :placeholder="`${lang_no3.placeholder.day_pick}`"
                  />
                </b-col>
              </b-row>
              <b-row :class="{ 'col-margin-bot-1': allDay_status == 'checked' }">
                <b-col lg="12">
                  <b-form-checkbox v-model="allDay_status" value="checked" name="checkbox" class="check-col">
                    <template>{{ lang_no3.placeholder.allDay }}</template>
                  </b-form-checkbox>
                </b-col>
              </b-row>
              <b-row v-if="allDay_status != 'checked'" class="col-margin-bot-2">
                <b-col lg="12">
                  <multiselect v-model="time_delivery[0]" label="select_txt" :options="dataTimeRange"
                               placeholder="เลือกช่วงเวลา"
                  />
                </b-col>
                <!-- {{ time_delivery[0] }} -->
              </b-row>
              <b-row class="col-margin-bot-1">
                <b-col lg="1">
                  <div class="number-blue d-flex justify-content-center">4</div>
                </b-col>
                <b-col>
                  <span class="d-flex text-middle">{{ lang_no4.title }}</span>
                </b-col>
              </b-row>
              <b-row class="col-margin-bot-2">
                <b-col lg="12">
                  <!-- <div class="form-group">
                    <multiselect v-model="dataNewTruck" label="name_model" :options="dataTruckType" placeholder="เลือกประเภทรถ" />
                  </div> -->
                  <!-- <div class="form-group" @click="activeTruckType()">
                    <b-form-input v-model="data_truck_type_sel_txt" type="text" class="form-control" :placeholder="lang_no4.placeholder.truck_type" disabled />
                  </div> -->
                  <div class="form-group Truck_type_info" @click="activeTruckType()">
                    <div v-if="data_truck_type_sel.length > 0" class="form-control d-flex align-items-center">
                      <img v-if="dataTruckTypeGroup[0].status == 'checked'" src="@/assets/images/truck_img/4_wheels.png"
                           width="60px" style="margin-right: 20px"
                      />
                      <img v-else-if="dataTruckTypeGroup[1].status == 'checked'"
                           src="@/assets/images/truck_img/6_wheels.png" width="100px" style="margin-right: 20px"
                      />
                      <img v-else-if="dataTruckTypeGroup[2].status == 'checked'"
                           src="@/assets/images/truck_img/10_wheels.png" width="105px" style="margin-right: 20px"
                      />
                      <img v-else-if="dataTruckTypeGroup[3].status == 'checked'"
                           src="@/assets/images/truck_img/trailer2.png" width="200px" style="margin-right: 20px"
                      />
                      <img v-else-if="dataTruckTypeGroup[4].status == 'checked'"
                           src="@/assets/images/truck_img/trailer.png" width="150px" style="margin-right: 20px"
                      />
                      <img v-else-if="dataTruckTypeGroup[5].status == 'checked'"
                           src="@/assets/images/truck_img/tow_truck.png" width="200px" style="margin-right: 20px"
                      />
                      {{ data_truck_type_sel_txt }}
                    </div>
                    <div v-else class="form-control d-flex align-items-center" style="color: #84889A">
                      {{ lang_no4.placeholder.truck_type }}
                    </div>
                  </div>
                </b-col>
                <b-col lg="12">
                  <div class="form-group" @click="activeServiceEtc()">
                    <b-form-input v-model="data_service_sel" type="text" class="form-control"
                                  :placeholder="lang_no4.placeholder.services" disabled
                    />
                  </div>
                </b-col>
              </b-row>
              <b-row class="col-margin-bot-1">
                <b-col lg="1">
                  <div class="number-blue d-flex justify-content-center">5</div>
                </b-col>
                <b-col>
                  <span class="d-flex text-middle">{{ lang_no5.title }}</span>
                  <span class="d-flex text-middle text-sub">{{ lang_no5.sub_title }}</span>
                </b-col>
              </b-row>
              <b-row v-if="budget_display" class="col-margin-bot-1">
                <b-col>
                  <span class="font-size-16 font-color-blue">{{ lang_no5.tip }}</span>
                </b-col>
              </b-row>
              <template v-if="budget_display">
                <div>
                  <div class="budget_box col-margin-bot-1">
                    <b-row class="font-size-14">
                      <b-col lg="4">
                        <span>{{ lang_no5.truck_type }}</span>
                      </b-col>
                      <b-col class="text-right" lg="8">
                        <img v-if="data_truck_type_standard_cost[0].id_group == '1'"
                             src="@/assets/images/truck_img/4_wheels.png" width="50px" style="margin-right: 20px"
                        />
                        <img v-else-if="data_truck_type_standard_cost[0].id_group == '2'"
                             src="@/assets/images/truck_img/6_wheels.png" width="50px" style="margin-right: 20px"
                        />
                        <img v-else-if="data_truck_type_standard_cost[0].id_group == '3'"
                             src="@/assets/images/truck_img/10_wheels.png" width="50px" style="margin-right: 20px"
                        />
                        <img v-else-if="data_truck_type_standard_cost[0].id_group == '4'"
                             src="@/assets/images/truck_img/trailer2.png" width="50px" style="margin-right: 20px"
                        />
                        <img v-else-if="data_truck_type_standard_cost[0].id_group == '5'"
                             src="@/assets/images/truck_img/trailer.png" width="50px" style="margin-right: 20px"
                        />
                        <img v-else-if="data_truck_type_standard_cost[0].id_group == '6'"
                             src="@/assets/images/truck_img/tow_truck.png" width="50px" style="margin-right: 20px"
                        />
                        <span
                          v-if="data_truck_type_standard_cost[0].truck_type_name_sel == null || data_truck_type_standard_cost[0].truck_type_name_sel == ''"
                        >
                          - </span>
                        <span v-else>{{ data_truck_type_standard_cost[0].truck_type_name_sel }}</span>
                      </b-col>
                    </b-row>
                    <br />
                    <b-row class="font-size-14">
                      <b-col lg="4">
                        <span>{{ lang_no5.standard_rating }}</span>
                      </b-col>
                      <b-col class="text-right" lg="8">
                        <span v-if="data_truck_type_standard_cost[0].prices_pre_km > 0">{{
                          data_truck_type_standard_cost[0].prices_pre_km
                        }} {{ lang_no5.bath }} / {{ lang_g.km }}</span>
                        <span v-else>-</span>
                      </b-col>
                    </b-row>
                    <b-row class="font-size-14">
                      <b-col lg="4">
                        <span>{{ lang_no5.total_distance }}</span>
                      </b-col>
                      <b-col class="text-right" lg="8">
                        <span v-if="data_truck_type_standard_cost[0].distanceCal > 0">{{
                          formatDistance(data_truck_type_standard_cost[0].distanceCal)
                        }} {{ lang_g.km }}</span>
                        <span v-else>-</span>
                      </b-col>
                    </b-row>
                    <br />
                    <b-row class="font-size-18">
                      <b-col class="font-color-blue" lg="4">
                        <span>{{ lang_no5.standard_rating }}</span>
                      </b-col>
                      <b-col class="text-right font-color-blue" lg="8">
                        <span v-if="data_truck_type_standard_cost[0].total_cost > 0">{{
                          formatPrice(data_truck_type_standard_cost[0].total_cost)
                        }} {{ lang_no5.bath }}</span>
                        <span v-else>-</span>
                      </b-col>
                    </b-row>
                  </div>
                </div>
                <template v-if="all_cost_display">
                  <transition-group name="bounce" class="custom-transition" enter-active-class="bounceInLeft"
                                    leave-active-class="bounceOutRight" tag="div"
                  >
                    <div v-for="(option, index) in data_truck_type_standard_cost" :key="index">
                      <div v-if="index != 0" class="budget_box col-margin-bot-1">
                        <b-row class="font-size-14">
                          <b-col lg="4">
                            <span>{{ lang_no5.truck_type }}</span>
                          </b-col>
                          <b-col class="text-right" lg="8">
                            <img v-if="data_truck_type_standard_cost[index].id_group == '1'"
                                 src="@/assets/images/truck_img/4_wheels.png" width="60px" style="margin-right: 20px"
                            />
                            <img v-else-if="data_truck_type_standard_cost[index].id_group == '2'"
                                 src="@/assets/images/truck_img/6_wheels.png" width="60px" style="margin-right: 20px"
                            />
                            <img v-else-if="data_truck_type_standard_cost[index].id_group == '3'"
                                 src="@/assets/images/truck_img/10_wheels.png" width="60px" style="margin-right: 20px"
                            />
                            <img v-else-if="data_truck_type_standard_cost[index].id_group == '4'"
                                 src="@/assets/images/truck_img/trailer2.png" width="60px" style="margin-right: 20px"
                            />
                            <img v-else-if="data_truck_type_standard_cost[index].id_group == '5'"
                                 src="@/assets/images/truck_img/trailer.png" width="60px" style="margin-right: 20px"
                            />
                            <img v-else-if="data_truck_type_standard_cost[index].id_group == '6'"
                                 src="@/assets/images/truck_img/tow_truck.png" width="60px" style="margin-right: 20px"
                            />
                            <span
                              v-if="data_truck_type_standard_cost[index].truck_type_name_sel == null || data_truck_type_standard_cost[index].truck_type_name_sel == ''"
                            >
                              - </span>
                            <span v-else>{{ data_truck_type_standard_cost[index].truck_type_name_sel }}</span>
                          </b-col>
                        </b-row>
                        <br />
                        <b-row class="font-size-14">
                          <b-col lg="4">
                            <span>{{ lang_no5.standard_rating }}</span>
                          </b-col>
                          <b-col class="text-right" lg="8">
                            <span v-if="data_truck_type_standard_cost[index].prices_pre_km > 0">{{
                              data_truck_type_standard_cost[index].prices_pre_km
                            }} {{ lang_no5.bath }} / {{
                              lang_g.km
                            }}</span>
                            <span v-else>-</span>
                          </b-col>
                        </b-row>
                        <b-row class="font-size-14">
                          <b-col lg="4">
                            <span>{{ lang_no5.total_distance }}</span>
                          </b-col>
                          <b-col class="text-right" lg="8">
                            <span v-if="data_truck_type_standard_cost[index].distanceCal > 0">{{
                              formatDistance(data_truck_type_standard_cost[index].distanceCal)
                            }} {{ lang_g.km }}</span>
                            <span v-else>-</span>
                          </b-col>
                        </b-row>
                        <br />
                        <b-row class="font-size-18">
                          <b-col class="font-color-blue" lg="4">
                            <span>{{ lang_no5.standard_rating }}</span>
                          </b-col>
                          <b-col class="text-right font-color-blue" lg="8">
                            <span v-if="data_truck_type_standard_cost[index].total_cost > 0">{{
                              formatPrice(data_truck_type_standard_cost[index].total_cost)
                            }} {{ lang_no5.bath }}</span>
                            <span v-else>-</span>
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                  </transition-group>
                </template>
                <b-row class="font-size-16 col-margin-bot-1">
                  <b-col class="font-color-blue text-center">
                    <span v-if="all_cost_btn" class="active_all_cost_display" @click="active_all_cost_display()">{{
                      lang_no5.all_cost_btn_samll
                    }}</span>
                    <span v-else class="active_all_cost_display" @click="active_all_cost_display()">{{
                      lang_no5.all_cost_btn_full
                    }}</span>
                  </b-col>
                </b-row>
              </template>
              <!-- <br /> -->
              <b-row class="font-size-16">
                <b-col lg="12">
                  <div class="form-group">
                    <span>{{ lang_no5.placeholder.input_prices }}</span>
                  </div>
                </b-col>
                <b-col lg="12">
                  <div class="form-group">
                    <div class="input-group">
                      <!-- <b-form-input v-model="totalCost" type="number" class="prepend form-control" style="cursor: pointer;" :placeholder="lang_no5.placeholder.input_prices" /> -->
                      <VuetifyMoney v-model="totalCost" :placeholder="lang_no5.placeholder.input_prices"
                                    :readonly="readonly5" :disabled="disabled5" :outlined="outlined5" :clearable="clearable5"
                                    :options="options5"
                      />
                      <div class="input-group-prepend">
                        <span class="add_tons input-group-text">{{ lang_no5.bath }}</span>
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-row class="font-size-16" style="display: none;">
                <b-col>
                  <div class="custom-checkbox">
                    <b-form-checkbox v-model="data_offers_allowed" value="checked" name="checkbox" class="check-col">
                      <template>{{ lang_no5.offers_are_allowed }}</template>
                      <br />
                    </b-form-checkbox>
                  </div>
                </b-col>
              </b-row>
              <hr />
              <b-row class="font-size-16">
                <b-col>
                  <div class="d-flex justify-content-center next_big_btn" @click="checkAllDataInput()">
                    <!-- <div class="d-flex justify-content-center next_big_btn" @click="activeSummaryInfo()"> -->
                    <!-- <div class="d-flex justify-content-center next_big_btn" @click="confirmAlert()"> -->
                    <span class="margin-top-bot-auto">{{ lang_g.btn.next }}</span>
                  </div>
                </b-col>
              </b-row>
            </div>
          </simplebar>
        </div>
      </b-col>
      <!-- sub menu -->
      <!-- เมนูเลือกประเภทสินค้า -->
      <transition name="bounce" enter-active-class="fadeInLeft" leave-active-class="fadeOutLeft">
        <div v-if="open_goods_type_status" class="col-main-menu h-100vh goods_sel sub_menu_plan"
             :class="{ active: open_goods_type_status }"
        >
          <div class="card">
            <div class="card-header bg-transparent border-bottom">
              <b-row>
                <b-col class="d-flex align-items-center">
                  <h5 class="mb-0">{{ lang_no1.sub_menu.title }}</h5>
                </b-col>
                <b-col class="align-items-center">
                  <button type="button" aria-label="Close" class="close"
                          @click="activeGoodsType()"
                  >
                    <span>x</span>
                  </button>
                </b-col>
              </b-row>
            </div>
            <simplebar class="card-body" style="max-height: 87.5vh; min-height: 87.5vh">
              <div style="padding: 4%;">
                <b-row>
                  <b-col>
                    <div v-for="(option, index) in dataGoodsType_data" :key="option.goods_type_id">
                      <div v-if="option.goods_type_name_th == 'อื่นๆ'">
                        <b-row>
                          <b-col lg="2" md="2">
                            <b-form-checkbox v-model="dataGoodsType_data[index].goods_type_status" value="checked"
                                             name="checkbox" class="check-col" @change="goods_type_sel(option)"
                            >
                              <template v-if="goods_lang">{{ option.goods_type_name_th }}</template>
                              <template v-else>{{ option.goods_type_name_en }}</template>
                            </b-form-checkbox>
                          </b-col>
                          <b-col lg="6" md="6">
                            <b-form-input v-model="dataGoodsType_note" type="text" class="form-control"
                                          :style="{ display: dataGoodsType_data[index].goods_type_status == 'checked' ? 'block' : 'none' }"
                                          @input="goods_type_sel(dataGoodsType_note)"
                            />
                          </b-col>
                        </b-row>
                      </div>
                      <div v-else-if="option.goods_type_id != '1'">
                        <b-form-checkbox v-model="dataGoodsType_data[index].goods_type_status" value="checked"
                                         name="checkbox" class="check-col" @change="goods_type_sel(option)"
                        >
                          <template v-if="goods_lang">{{ option.goods_type_name_th }}</template>
                          <template v-else>{{ option.goods_type_name_en }}</template>
                        </b-form-checkbox>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </simplebar>
          </div>
        </div>
      </transition>
      <!-- เมนูเลือกค้นหาจุดรับ - ส่งสินค้า -->
      <transition name="bounce" enter-active-class="fadeInLeft" leave-active-class="fadeOutLeft">
        <div v-if="open_location_sel_status" class="col-main-menu h-100vh location_menu"
             :class="{ active: open_location_sel_status }"
        >
          <div class="card">
            <div class="card-header bg-transparent border-bottom">
              <b-row>
                <b-col>
                  <h5 class="mb-0">{{ lang_no2.sub_menu.title }}</h5>
                </b-col>
              </b-row>
            </div>
            <simplebar class="card-body" style="max-height: 87.5vh; min-height: 87.5vh">
              <div style="padding: 4%;">
                <b-row class="col-margin-bot-1">
                  <span class="d-flex justify-content-center font-size-16 btn_back_main_menu"
                        @click="activeLocation()"
                  ><i class="ri-arrow-left-line" style="margin-right:5px;" />{{
                    lang_g.btn.back
                  }}</span>
                </b-row>
                <b-row v-if="activeLocation_index < 1" class="col-margin-bot-1">
                  <span class="location_menu_content">{{ lang_no2.sub_menu.point }} {{ activeLocation_index + 1 }} - {{
                    lang_no2.sub_menu.pick_up
                  }}</span>
                </b-row>
                <b-row v-else class="col-margin-bot-1">
                  <span class="location_menu_content">{{ lang_no2.sub_menu.point }} {{ activeLocation_index + 1 }} - {{
                    lang_no2.sub_menu.deliver
                  }}</span>
                </b-row>
                <b-row class="col-margin-bot-1">
                  <b-form-input v-model="search_text" type="text" class="form-control"
                                :placeholder="lang_no2.sub_menu.placeholder.find" @input="timeout_search_location(search_text)"
                  />
                </b-row>
                <b-row v-if="current_location_permission_flag" class="col-margin-bot-1">
                  <span class="d-flex justify-content-center font-size-16 current_location_btn"
                        :class="{ active: activeCurrentLocation_Status }" @click="active_current_location()"
                  ><i
                    class="ri-focus-3-line" style="margin-right:5px;"
                  />{{
                    lang_no2.sub_menu.current_location
                  }}</span>
                </b-row>
                <b-row>
                  <b-col>
                    <hr />
                  </b-col>
                </b-row>
                <b-row v-if="search_result">
                  <b-col lg="12">
                    <div>
                      <span class="search_result">
                        {{ lang_no2.sub_menu.result }}
                      </span>
                      <br />
                    </div>
                  </b-col>
                  <b-col v-if="!search_result_after" lg="12" class="text-center">
                    <div v-if="!no_result">
                      <span>
                        <br />
                        <br />
                        <b-spinner class="m-2" variant="primary" role="status" />
                      </span>
                    </div>
                    <div v-else>
                      <span>
                        <br />
                        <br />
                        {{ lang_no2.sub_menu.no_result }}
                      </span>
                    </div>
                  </b-col>
                  <b-col v-for="(data, index) in search_result_data" v-else :key="index" lg="12">
                    <b-row class="search_result_text">
                      <b-col md="10">
                        <div class="text-left" @click="select_location(data)">
                          <span class="location_name">
                            {{ data.location_name }}
                          </span>
                          <br />
                          <span class="location_addr">
                            {{ data.location_addr }}
                          </span>
                        </div>
                      </b-col>
                      <b-col md="2" class="d-flex align-items-center justify-content-center">
                        <img v-if="data.favorite_flg == 0 || data.favorite_flg == 2"
                             src="@/assets/images/plans_display/star_empty.png" width="24px" height="24px"
                             @click="addFavoriteLocation(data, index)"
                        />
                        <img v-else src="@/assets/images/plans_display/star@2x.png" width="24px" height="24px"
                             @click="addFavoriteLocation(data, index)"
                        />
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row v-else>
                  <b-col>
                    <b-tabs justified nav-class="nav-tabs-custom">
                      <b-tab active>
                        <template v-slot:title>
                          <span class="d-none d-sm-inline-block">{{ lang_no2.sub_menu.save_location }}</span>
                        </template>
                        <b-col v-for="(data, index) in current_favorite_location_data" :key="index" lg="12">
                          <b-row class="search_result_text">
                            <b-col md="10">
                              <div class="text-left" @click="select_location2(data)">
                                <span class="location_name">
                                  {{ data.location_name }}
                                </span>
                                <br />
                                <span class="location_addr">
                                  {{ data.location_addr }}
                                </span>
                              </div>
                            </b-col>
                            <b-col md="2" class="d-flex align-items-center justify-content-center">
                              <img src="@/assets/images/plans_display/star@2x.png" width="24px" height="24px"
                                   @click="deleteFavoriteLocation(index)"
                              />
                            </b-col>
                          </b-row>
                        </b-col>
                        <div v-show="current_favorite_location_data.length == 0">
                          <div class="d-flex align-items-center justify-content-center" style="margin-top: 20px">
                            <span>
                              {{ lang_no2.sub_menu.not_found }}
                            </span>
                          </div>
                        </div>
                      </b-tab>
                      <b-tab>
                        <template v-slot:title>
                          <span class="d-none d-sm-inline-block">{{ lang_no2.sub_menu.history_location }}</span>
                        </template>
                        <b-col v-for="(data, index) in location_previous_plans_data" :key="index" lg="12">
                          <b-row class="search_result_text">
                            <b-col md="10">
                              <div class="text-left" @click="select_location2(data)">
                                <span class="location_name">
                                  {{ data.location_name }}
                                </span>
                                <br />
                                <span class="location_addr">
                                  {{ data.location_addr }}
                                </span>
                              </div>
                            </b-col>
                          </b-row>
                        </b-col>
                        <div v-show="location_previous_plans_data.length == 0">
                          <div class="d-flex align-items-center justify-content-center" style="margin-top: 20px">
                            <span>
                              {{ lang_no2.sub_menu.not_found_plans }}
                            </span>
                          </div>
                        </div>
                      </b-tab>
                    </b-tabs>
                  </b-col>
                </b-row>
              </div>
            </simplebar>
          </div>
        </div>
      </transition>
      <!-- เมนูเลือกประเภทรถ -->
      <transition name="bounce" enter-active-class="fadeInLeft" leave-active-class="fadeOutLeft">
        <div v-if="open_truck_type_status" class="col-main-menu h-100vh goods_sel sub_menu_plan"
             :class="{ active: open_truck_type_status }"
        >
          <div class="card">
            <div class="card-header bg-transparent border-bottom">
              <b-row>
                <b-col class="d-flex align-items-center">
                  <h5 class="mb-0">{{ lang_no4.sub_menu1.title }}</h5>
                </b-col>
                <b-col class="align-items-center">
                  <button type="button" aria-label="Close" class="close"
                          @click="activeTruckType()"
                  >
                    <span>x</span>
                  </button>
                </b-col>
              </b-row>
            </div>
            <simplebar class="card-body" style="max-height: 87.5vh; min-height: 87.5vh">
              <div style="padding: 4%;">
                <div v-for="(option2, index2) in dataTruckTypeGroup" :key="index2">
                  <b-row>
                    <b-col class="d-flex align-items-center">
                      <b-form-checkbox v-model="dataTruckTypeGroup[index2].status" value="checked"
                                       :name="'checkbox_' + option2.id_group" @change="truck_type_group_sel(option2, index2)"
                      >
                        <template v-if="goods_lang">{{ option2.name_group_th }}</template>
                        <template v-else>{{ option2.name_group_en }}</template>
                      </b-form-checkbox>
                    </b-col>
                    <b-col class="d-flex align-items-start justify-content-end">
                      <img v-if="option2.id_group == '1'" src="@/assets/images/truck_img/4_wheels.png" width="60px" />
                      <img v-if="option2.id_group == '2'" src="@/assets/images/truck_img/6_wheels.png" width="100px" />
                      <img v-if="option2.id_group == '3'" src="@/assets/images/truck_img/10_wheels.png" width="105px" />
                      <img v-if="option2.id_group == '4'" src="@/assets/images/truck_img/trailer2.png" width="200px" />
                      <img v-if="option2.id_group == '5'" src="@/assets/images/truck_img/trailer.png" width="150px" />
                      <img v-if="option2.id_group == '6'" src="@/assets/images/truck_img/tow_truck.png" width="200px" />
                    </b-col>
                  </b-row>
                  <br />
                  <b-row>
                    <template v-for="(option, index) in dataTruckType">
                      <b-col v-if="option.other_flag == '1' && option.id_group == option2.id_group"
                             :key="option.id_model" lg="12"
                      >
                        <b-form-checkbox v-model="dataTruckType[index].status" value="checked"
                                         :name="'checkbox_' + option.id_model" class="check-col" @change="truck_type_sel(option)"
                        >
                          <template v-if="goods_lang">{{ option.name_model_th }}</template>
                          <template v-else>{{ option.name_model_en }}</template>
                          <!-- <template>{{ dataTruckType[option.id_model - 1].status }}</template> -->
                        </b-form-checkbox>
                        <b-form-input id="truck_type_other" v-model="dataTruckType_note[index2]" type="text"
                                      class="form-control"
                                      :style="{ display: dataTruckType[index].status == 'checked' ? 'block' : 'none' }"
                        />
                      </b-col>
                      <b-col v-else-if="option.other_flag == '0' && option.id_group == option2.id_group"
                             :key="option.id_model" lg="6"
                      >
                        <b-form-checkbox v-model="dataTruckType[index].status" value="checked" class="check-col"
                                         :name="'checkbox_' + option.id_model" @change="truck_type_sel(option)"
                        >
                          <template v-if="goods_lang">{{ option.name_model_th }}</template>
                          <template v-else>{{ option.name_model_en }}</template>
                          <!-- <template>{{ dataTruckType[option.id_model - 1].status }}</template> -->
                        </b-form-checkbox>
                      </b-col>
                    </template>
                  </b-row>
                  <hr />
                  <br />
                </div>
              </div>
            </simplebar>
          </div>
        </div>
      </transition>
      <!-- เมนูเลือกบริการอื่น ๆ -->
      <transition name="bounce" enter-active-class="fadeInLeft" leave-active-class="fadeOutLeft">
        <div v-if="open_service_sel_status" class="col-main-menu h-100vh goods_sel sub_menu_plan"
             :class="{ active: open_service_sel_status }"
        >
          <div class="card">
            <div class="card-header bg-transparent border-bottom">
              <b-row>
                <b-col>
                  <h5 class="mb-0">{{ lang_no4.sub_menu2.title }}</h5>
                </b-col>
                <b-col>
                  <button type="button" aria-label="Close" class="close" @click="activeServiceEtc()">×</button>
                </b-col>
              </b-row>
            </div>
            <simplebar class="card-body" style="max-height: 87.5vh; min-height: 87.5vh">
              <div style="padding: 4%;">
                <b-row class="col-margin-bot-1">
                  <b-col style="color: #FF5D5D;">
                    <span>
                      {{ lang_no4.sub_menu2.additional_services }}
                    </span>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <div v-for="(option, index) in data_service_etc" :key="option.service_etc_id">
                      <div v-if="option.service_etc_id == '5'">
                        <b-row>
                          <b-col lg="12" md="12">
                            <b-form-checkbox v-model="data_service_etc[index].service_sel_status" value="checked"
                                             name="checkbox" class="check-col" @change="getServiceEtc()"
                            >
                              <template v-if="goods_lang">{{ option.name_th }}</template>
                              <template v-else>{{ option.name_en }}</template>
                            </b-form-checkbox>
                          </b-col>
                          <b-col lg="12" md="12">
                            <b-form-input v-model="data_service_ck_note" type="text" class="form-control"
                                          name="goods_type_other"
                                          :style="{ display: data_service_etc[index].service_sel_status == 'checked' ? 'block' : 'none' }"
                                          @input="getServiceEtc()"
                            />
                          </b-col>
                        </b-row>
                      </div>
                      <div v-else-if="option.service_etc_id != '5'">
                        <b-form-checkbox v-model="data_service_etc[index].service_sel_status" value="checked"
                                         name="checkbox" class="check-col" @change="getServiceEtc()"
                        >
                          <template v-if="goods_lang">{{ option.name_th }}</template>
                          <template v-else>{{ option.name_en }}</template>
                        </b-form-checkbox>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </simplebar>
          </div>
        </div>
      </transition>
      <!-- เมนูสรุปข้อมูลการสร้างแผนงาน -->
      <transition name="bounce" enter-active-class="fadeInLeft" leave-active-class="fadeOutLeft">
        <div v-if="summary_info_plan_status" class="col-main-menu h-100vh summary-of-plan"
             :class="{ active: summary_info_plan_status }"
        >
          <div class="card" style="max-height: 87.5vh; min-height: 87.5vh">
            <b-row>
              <b-col lg="12">
                <simplebar class="card-body" style="max-height: 87.5vh; min-height: 87.5vh">
                  <div style="padding: 1% 1% 0 1%;" class="font-size-18">
                    <b-row class="col-margin-bot-1">
                      <b-col lg="1">
                        <span class="d-flex justify-content-left btn_back_main_menu" @click="activeSummaryInfo()"><i
                          class="ri-arrow-left-line" style="margin-right:5px;"
                        />ย้อนกลับ</span>
                      </b-col>
                    </b-row>
                    <b-row class="col-margin-bot-1">
                      <b-col>
                        <span class="summary-title">{{ lang_sum.title }}</span>
                      </b-col>
                    </b-row>
                    <b-row class="col-margin-bot-1">
                      <b-col lg="1">
                        {{ lang_sum.goods_type }}
                      </b-col>
                      <b-col lg="5">
                        <span v-if="data_goods_type_sel_full != ''" class="font-color-blue"> {{
                          data_goods_type_sel_full
                        }} </span>
                        <span v-else class="font-color-blue">
                          -
                        </span>
                      </b-col>
                      <b-col lg="1">
                        {{ lang_sum.date_create }}
                      </b-col>
                      <b-col lg="5">
                        <span class="font-color-blue">
                          {{ new Date() | moment('dd DD/MM/YYYY HH:mm') }}
                        </span>
                      </b-col>
                    </b-row>
                    <b-row class="col-margin-bot-1">
                      <b-col lg="1">
                        {{ lang_sum.detail }}
                      </b-col>
                      <b-col lg="5">
                        <span v-if="dataGoodsType_etc == ''" class="font-color-blue">-</span>
                        <span v-else class="font-color-blue">
                          {{ dataGoodsType_etc }}
                        </span>
                      </b-col>
                      <b-col lg="1">
                        {{ lang_sum.weight }}
                      </b-col>
                      <b-col lg="5">
                        <span v-if="data_goods_tons > 0" class="font-color-blue">{{ formatPrice(data_goods_tons) }} {{
                          lang_sum.tons
                        }}</span>
                        <span v-else class="font-color-blue">-</span>
                      </b-col>
                    </b-row>
                    <b-row class="col-margin-bot-1">
                      <b-col lg="1">
                        {{ lang_sum.location }}
                      </b-col>
                      <b-col lg="5">
                        <ul class="list-unstyled activity-wid">
                          <li v-for="(data, index) in data_location" :key="index" class="activity-list">
                            <b-row>
                              <div class="activity-icon avatar-xs">
                                <span v-if="index > 0" class="avatar-title bg-soft-primary rounded-circle"
                                      style="color: #B0BAC4"
                                >
                                  <i :class="`${data.icon}`" />
                                </span>
                                <span v-else class="avatar-title bg-soft-primary text-primary rounded-circle">
                                  <i :class="`${data.icon}`" />
                                </span>
                              </div>
                              <b-col>
                                <div>
                                  <span> {{ lang_sum.point + ' ' + (index + 1) + ' - ' + lang_sum.deliver }} </span>
                                  <br />
                                  <span v-if="data_location[index].location_name != ''" class="font-color-blue">{{
                                    data_location[index].location_name + data_location[index].location_addr
                                  }}</span>
                                  <span v-else class="font-color-blue">-</span>
                                  <br />
                                  <span v-if="allDay_status == 'checked' && index == 0" class="font-color-blue">{{
                                    date_time_delivery[0].date | moment('dd DD/MM/YYYY')
                                  }} {{ '(ตลอดวัน)' }} </span>
                                  <span v-else-if="index == 0" class="font-color-blue">{{
                                    date_time_delivery[0].date |
                                      moment('dd DD/MM/YYYY')
                                  }} ( {{ time_delivery[0].select_txt }} )</span>
                                </div>
                              </b-col>
                            </b-row>
                          </li>
                        </ul>
                      </b-col>
                    </b-row>
                    <b-row class="col-margin-bot-1">
                      <b-col lg="1">
                        {{ lang_sum.total_distance }}
                      </b-col>
                      <b-col lg="5">
                        <span v-if="distanceCal > 0" class="font-color-blue">{{ formatDistance(distanceCal) }} {{
                          lang_g.km
                        }}</span>
                        <span v-else class="font-color-blue">-</span>
                      </b-col>
                      <b-col lg="1">
                        {{ lang_sum.truck_type }}
                      </b-col>
                      <b-col lg="5">
                        <img v-if="data_truck_type_standard_cost[0].id_group == '1'"
                             src="@/assets/images/truck_img/4_wheels.png" width="50px" style="margin-right: 20px"
                        />
                        <img v-else-if="data_truck_type_standard_cost[0].id_group == '2'"
                             src="@/assets/images/truck_img/6_wheels.png" width="50px" style="margin-right: 20px"
                        />
                        <img v-else-if="data_truck_type_standard_cost[0].id_group == '3'"
                             src="@/assets/images/truck_img/10_wheels.png" width="50px" style="margin-right: 20px"
                        />
                        <img v-else-if="data_truck_type_standard_cost[0].id_group == '4'"
                             src="@/assets/images/truck_img/trailer2.png" width="50px" style="margin-right: 20px"
                        />
                        <img v-else-if="data_truck_type_standard_cost[0].id_group == '5'"
                             src="@/assets/images/truck_img/trailer.png" width="50px" style="margin-right: 20px"
                        />
                        <img v-else-if="data_truck_type_standard_cost[0].id_group == '6'"
                             src="@/assets/images/truck_img/tow_truck.png" width="50px" style="margin-right: 20px"
                        />
                        <span v-if="data_truck_type_sel_txt != ''" class="font-color-blue">{{
                          data_truck_type_sel_txt
                        }}</span>
                        <span v-else class="font-color-blue">-</span>
                      </b-col>
                    </b-row>
                    <b-row class="col-margin-bot-1">
                      <b-col lg="1">
                        {{ lang_sum.services }}
                      </b-col>
                      <b-col lg="5">
                        <span v-if="data_service_sel_full != ''" class="font-color-blue">{{
                          data_service_sel_full
                        }}</span>
                        <span v-else class="font-color-blue">-</span>
                      </b-col>
                    </b-row>
                    <b-row class="col-margin-bot-1">
                      <b-col lg="1">
                        {{ lang_sum.standard_rating }}
                      </b-col>
                      <b-col lg="5">
                        <span v-if="data_truck_type_standard_cost[0].total_cost > 0" class="font-color-blue">{{
                          formatPrice(data_truck_type_standard_cost[0].total_cost)
                        }} {{ lang_no5.bath }}</span>
                        <span v-else class="font-color-blue">-</span>
                      </b-col>
                      <b-col lg="1">
                        {{ lang_sum.amount }}
                      </b-col>
                      <b-col lg="5">
                        <!-- <span v-if="totalCost > 0 && data_offers_allowed == 'checked'" style="font-size:24px; color:#343C48; font-weight: 500;">{{ formatPrice(totalCost) }} {{ lang_g.bath }} <span class="font-color-blue font-size-18">(อนุญาตให้มีการยื่นข้อเสนอ)</span></span> -->
                        <span v-if="totalCost > 0" style="font-size:24px; color:#343C48; font-weight: 500;">{{
                          formatPrice(totalCost)
                        }} {{ lang_g.bath }}</span>
                        <span v-else class="font-color-blue">-</span>
                      </b-col>
                    </b-row>
                  </div>
                  <div style="padding: 0 0 0 0;" class="font-size-18">
                    <b-col class="col-margin-bot-1">
                      <hr />
                      <span>{{ lang_sum.bid_period }}</span>
                      <span class="font-color-red"> * </span>
                      <span class="font-size-14" style="font-weight:300;">{{ lang_sum.bid_period_detail }}</span>
                    </b-col>
                    <b-col lg="6">
                      <select v-model="deadline_selected" class="form-control font-color-blue color-blue-important">
                        <template v-if="goods_lang">
                          <option value="" disabled> --- โปรดเลือกระยะเวลาเสนอราคา --- </option>
                          <option value="not_specified" selected>ไม่กำหนดเวลาเสนอราคา</option>
                          <option value="0">{{ deadline_select[0].texts_th }}</option>
                          <option v-if="data_between_date.diffDays > 0" value="-1">
                            {{ deadline_select[1].texts_th }}
                          </option>
                          <option v-if="data_between_date.diffDays > 3" value="-3">
                            {{ deadline_select[2].texts_th }}
                          </option>
                          <option v-if="data_between_date.diffDays > 7" value="-7">
                            {{ deadline_select[3].texts_th }}
                          </option>
                        </template>
                        <template v-else>
                          <option value="" disabled> --- Please select a bid period. --- </option>
                          <option value="not_specified" selected>not selected</option>
                          <option value="0">{{ deadline_select[0].texts_en }}</option>
                          <option v-if="data_between_date.diffDays > 0" value="-1">
                            {{ deadline_select[1].texts_en }}
                          </option>
                          <option v-if="data_between_date.diffDays > 3" value="-3">
                            {{ deadline_select[2].texts_en }}
                          </option>
                          <option v-if="data_between_date.diffDays > 7" value="-7">
                            {{ deadline_select[3].texts_en }}
                          </option>
                        </template>
                      </select>
                    </b-col>
                  </div>
                  <div style="padding: 0 0 0 0;" class="font-size-18">
                    <b-col>
                      <hr />
                      <b-row v-show="sp_create_flag == 1">
                        <b-col lg="12">
                          <b-form-checkbox v-model="sp_send_offer_flag" value="checked" name="checkbox"
                                           class="check-col"
                          >
                            <template>{{ lang_sum.sp_send_offer }}</template>
                          </b-form-checkbox>
                        </b-col>
                      </b-row>
                      <div class="d-flex justify-content-center save_big_btn" @click="checkDeadlineSelcet()">
                        <span class="margin-top-bot-auto">{{ lang_g.btn.confirm }}</span>
                      </div>
                    </b-col>
                  </div>
                </simplebar>
              </b-col>
              <!-- <b-col>
                <div style="padding: 0 1% 0 1%;" class="font-size-18">
                  <b-col class="col-margin-bot-1">
                    <hr />
                    <span>{{ lang_sum.bid_period }}</span>
                    <span class="font-color-red"> * </span>
                    <span class="font-size-14" style="font-weight:300;">{{ lang_sum.bid_period_detail }}</span>
                  </b-col>
                  <b-col lg="6">
                    <select v-model="deadline_selected" class="form-control font-color-blue color-blue-important">
                      <template v-if="goods_lang">
                        <option value="" disabled> --- โปรดเลือกระยะเวลาเสนอราคา --- </option>
                        <option value="not_specified" selected>ไม่กำหนดเวลาเสนอราคา</option>
                        <option :value="0">{{ deadline_select[0].texts_th }}</option>
                        <option v-if="data_between_date.diffDays > 0" :value="-1">{{ deadline_select[1].texts_th }}</option>
                        <option v-if="data_between_date.diffDays > 3" :value="-3">{{ deadline_select[2].texts_th }}</option>
                        <option v-if="data_between_date.diffDays > 7" :value="-7">{{ deadline_select[3].texts_th }}</option>
                      </template>
                      <template v-else>
                        <option value="" disabled> --- Please select a bid period. --- </option>
                        <option value="not_specified" selected>not selected</option>
                        <option :value="0">{{ deadline_select[0].texts_en }}</option>
                        <option v-if="data_between_date.diffDays > 0" :value="-1">{{ deadline_select[1].texts_en }}</option>
                        <option v-if="data_between_date.diffDays > 3" :value="-3">{{ deadline_select[2].texts_en }}</option>
                        <option v-if="data_between_date.diffDays > 7" :value="-7">{{ deadline_select[3].texts_en }}</option>
                      </template>
                    </select>
                  </b-col>
                </div>
              </b-col> -->
            </b-row>
            <!-- <b-row>
              <b-col>
                <div style="padding: 0 1% 0 1%;" class="font-size-18">
                  <b-col>
                    <hr />
                    <div class="d-flex justify-content-center save_big_btn" @click="checkDeadlineSelcet()">
                      <span class="margin-top-bot-auto">{{ lang_g.btn.confirm }}</span>
                    </div>
                  </b-col>
                </div>
              </b-col>
            </b-row> -->
          </div>
        </div>
      </transition>
      <b-col ref="map" lg="9" class="col-main-menu-tran z-index map" :class="{ active: modals_map }"
             @click="modals_active()"
      >
        <!-- <div>      
          <vl-map :load-tiles-while-animating="true" :load-tiles-while-interacting="true" :style="'height:' + heightMap">
            <vl-view :zoom.sync="zoom" :center.sync="center" :rotation.sync="rotation" />
            <vl-layer-tile>
              <vl-source-osm />
            </vl-layer-tile>
            <vl-layer-vector
              ref="vectorLayer"
              update-while-animating
              update-while-interacting
            >
              <vl-source-vector :features="features" />
              <vl-style-box>
                <vl-style-icon :src="data_location[0].iconLocation" :scale="1" :anchor="[0.5, 1]" />
              </vl-style-box>
              <vl-style-func :factory="styleFuncFactory" />
            </vl-layer-vector>
            <vl-source-vector ref="map_vector">
              <vl-feature>
                <vl-geom-point :coordinates="pick_up_1" />
                <vl-style-box>
                  <vl-style-icon :src="src" :scale="1" :anchor="[0.5, 1]" />
                </vl-style-box>
              </vl-feature>
              <vl-feature>
                <vl-geom-point :coordinates="coordinates[1]" />
                <vl-style-box>
                  <vl-style-icon :src="data_location[1].iconLocation" :scale="1" :anchor="[0.5, 1]" />
                </vl-style-box>
              </vl-feature>
            </vl-source-vector>
            <vl-source-vector ref="map_vector">
              <vl-feature v-for="(data,index) in data_location" :key="index">
                <vl-geom-point :coordinates="coordinates[index]" />
                <vl-style-box>
                  <vl-style-icon :src="data.iconLocation" :scale="1" :anchor="[0.5, 1]" />
                </vl-style-box>
              </vl-feature>
            </vl-source-vector>
            </vl-layer-vector>
          </vl-map>
        </div> -->
        <!-- <div ref="map_1" :style="{height: heightMap}" /> -->
        <div id="map_1" ref="map_1" />
        <div ref="popup_travel" class="custom-ol-popup-travel">
          <a ref="popup_travel_closer" href="#" class="custom-ol-popup-closer-travel" />
          <div ref="popup_travel_content" class="custom-ol-popup-travel-content"
               :class="{ show: popupTravelOnMapActiveStatus }"
          >
            <div class="popup-travel-title">
              <b-row>
                <b-col>
                  จุดที่ 1 - จุดที่ 2
                </b-col>
              </b-row>
            </div>
            <div class="popup-travel-text">
              <b-row>
                <b-col>
                  เวลาโดยประมาณ :
                </b-col>
                <b-col class="text-right">
                  {{ travelTime_display }}
                </b-col>
              </b-row>
            </div>
            <div class="popup-travel-text">
              <b-row>
                <b-col>
                  <span>ระยะทาง : </span>
                </b-col>
                <b-col class="text-right">
                  <span v-if="distanceCal > 0" class="font-color-blue">{{ formatDistance(distanceCal) }} {{
                    lang_g.km
                  }}</span>
                  <span v-else class="font-color-blue">-</span>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
        <div ref="popup" class="custom-ol-popup">
          <a ref="popup_closer" href="#" class="custom-ol-popup-closer" />
          <div ref="popup_content" class="custom-ol-popup-content" :class="{ show: popupOnMapActiveStatus }">
            <template v-if="!pinLocationEditFlag">
              <div class="popup-location-title" @click="activeEditLocationPinPointOnMap()">
                {{ pinLocationTitle }}
              </div>
              <div class="popup-location-text" @click="activeEditLocationPinPointOnMap()">
                {{ pinLocationText }}
              </div>
            </template>
            <template v-else>
              <div class="popup-location-title">
                <b-form-input v-model="pinLocationTitle2" type="text" class="form-control" />
              </div>
              <div class="popup-location-text">
                <b-form-input v-model="pinLocationText2" type="text" class="form-control" />
              </div>
            </template>
            <div class="popup-location-btn">
              <template v-if="!pinLocationEditFlag">
                <div class="d-flex justify-content-center next_big_btn" @click="selectThisLocation()">
                  <span class="margin-top-bot-auto">{{ lang_g.btn.select_here }}</span>
                </div>
              </template>
              <template v-else>
                <div class="row">
                  <div class="col-8">
                    <div class="d-flex justify-content-center next_big_btn" @click="editLocationPinPointOnMap()">
                      <span class="margin-top-bot-auto">{{ lang_g.btn.confirm }}</span>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="d-flex justify-content-center big_btn_red_white_bg"
                         @click="activeEditLocationPinPointOnMap()"
                    >
                      <span class="margin-top-bot-auto">{{ lang_g.btn.cancel }}</span>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </Layout>
</template>
<style>
@import '../../../../node_modules/ol-layerswitcher/dist/ol-layerswitcher.css';

body {
  overflow: hidden;
}

hr {
  border: 1px solid #e4e4e4;
}

#map_1 {
  height: calc(100vh - 115px);
  /* 100% of the viewport height - navbar height */
}

.col-main-menu-tran {
  padding: 0px;
}

.h-100vh {
  height: 100% !important;
}

.bg-darkgrey {
  background-color: darkgrey;
}

.planner_window {
  height: 100%;
  width: 30%;
}

.number-blue {
  height: 25px;
  width: 25px;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  color: #fff;
  background-color: #03aeef;
  border-radius: 50%;
}

.text-middle {
  font-size: 16px;
  text-align: center;
  line-height: -0.4em;
}

.text-sub {
  font-size: 12px;
}

.add_tons.input-group-text {
  background-color: white;
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
  border-left: 0px;
}

#payload.add_car {
  border-right: 0px;
}

.prepend {
  border-right: 0px;
}

.check-col {
  margin-bottom: 1rem;
}

.goods_sel.active {
  position: absolute;
  left: 480.4px;
  width: 480px;
}

.location_menu.active {
  position: absolute;
  width: 480px;
  z-index: 11;
}

.z-index.map {
  transition: 1s;
  z-index: 1;
  background-color: rgb(255, 255, 255);
}

.z-index.map.active {
  transition: 1s;
  z-index: 8;
  background-color: darkgrey;
}

.decoration {
  text-align: center;
  /* ตำแหน่งที่จะให้เส้นอยู่ Left/Right/Center */
}

.decoration-inside {
  height: 6vh;
  /* ความสูงของเส้น */
  width: 1px;
  /* ความหนาของเส้น */
  background-color: #0000001a;
  /* สีของเส้น */
  display: block;
}

.main_menu_plan {
  z-index: 10;
}

.sub_menu_plan {
  z-index: 9;
}

.next_big_btn {
  background-color: #0098d1;
  color: #fff;
  border-radius: 5px;
  height: 40px;
}

.next_big_btn:hover {
  background-color: #0280ad;
  cursor: pointer;
}

.avatar-title {
  /* background-color: #fff !important; */
  background-color: hsla(197, 100%, 89%, 0) !important;
}

.btn_add_location:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.btn_back_main_menu:hover {
  color: #0098d1;
  cursor: pointer;
}

.current_location_btn {
  cursor: pointer;
}

.current_location_btn.active {
  color: #0098d1;
}

input:disabled {
  cursor: pointer;
}

.location_menu_content {
  font-size: 24px;
  font-weight: 500;
}

.search_result {
  font-size: 20px;
  font-weight: 500;
}

.search_result_text {
  padding: 10px 7px 10px 7px;
  border-bottom: 1px solid #e4e4e4;
}

.search_result_text:hover {
  cursor: pointer;
  background-color: #f7f7f7;
}

.location_name {
  font-size: 15px;
  font-weight: 500;
}

.location_addr {
  font-size: 12px;
  font-weight: 500;
  color: #74788d;
}

.summary-of-plan.active {
  position: absolute;
  width: 100%;
  z-index: 13;
}

.summary-title {
  font-size: 32px;
  font-weight: 500;
}

.save_big_btn {
  background-color: #0098d1;
  color: #fff;
  border-radius: 5px;
  height: 40px;
  width: 20%;
}

.save_big_btn:hover {
  background-color: #0280ad;
  cursor: pointer;
}

.Truck_type_info>.form-control {
  /* background-color: #0098d1; */
  /* border: 1px solid #B0BAC4; */
  /* color: #0098d1; */
  /* border-radius: 5px; */
  height: 100px;
  /* width: 100%; */
  /* padding: 5px; */
}

.Truck_type_info:hover>.form-control {
  background-color: #f7f7f7;
  cursor: pointer;
}

.swal2-styled.swal2-cancel {
  border: 1px solid #8a9096;
  border-radius: 0.25em;
  background: initial;
  background-color: #ffffff;
  color: #343c48;
  font-size: 1em;
}

.budget_box {
  background: #f2f9fc 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding: 20px;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #ffffff;
  cursor: pointer;
}

.form-control:disabled:hover,
.form-control[readonly]:hover {
  background-color: #f7f7f7;
  cursor: pointer;
}

.active_all_cost_display:hover {
  cursor: pointer;
  text-decoration: underline;
}

.modals_active {
  z-index: 20;
  height: calc(100vh - 115px);
  width: calc(100vw - 1440px);
  position: absolute;
}
</style>
